#philosophy {
  padding: 5.208333333333334VW 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #6A6665;
}

#philosophy .title {
  margin: 0 auto;
  /* width: fit-content; */
  text-align: center;
  font-family: 'RegularFonts', sans-serif;
}

#philosophy .title-1,
#philosophy .title-2 {
  font-size: 26px;
  letter-spacing: 2px;
  line-height: 66px;
  font-family: 'RegularFonts', sans-serif;


}

#philosophy .title-1 {
  color: #FFFFFF;
  font-family: 'RegularFonts', sans-serif;
  padding-right: 10px;
  font-size: 35px;
}

#philosophy .title-2 {
  color: #E6D3B3;
  font-family: 'BlueL'
}

#philosophy .serv-title {
  color: #FFFFFF;
  font-family: 'RegularFonts', sans-serif;
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 24px;
  text-transform: uppercase;
}

#philosophy .serv-desc {
  color: #FFFFFF;
  font-family: 'RegularFonts', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 30px;
}

#philosophy .plan-blk {
  display: inline-block;
  padding-bottom: 70px;
  width: 450px
}

#philosophy .divider {
  height: 3px;
  width: 100px;
  opacity: 0.2;
  border-radius: 1.5px;
  background-color: #FFFFFF;
  margin: auto;
}

#philosophy .plan-det {
  /* max-width: 1300px; */
  /* display: flex;
    align-items: center;
    justify-content: center; */
  margin: 0 auto;
  width: 600px;
}

#philosophy .plan-det-col2 {
  width: 600px;
  margin-left: 30px;
  /* margin: auto; */
}

#philosophy .plan-details {
  display: flex;
  /* padding: .525VW 10.416666666666668VW; */
  max-width: 1300px;
  margin: auto;
}

#philosophy .plans {
  max-width: 1300px;
  margin: auto;
}

#philosophy .right {
  float: right;
  /* padding-left: 180px */
}

#philosophy .plan-det img,
#philosophy .plan-det-col2 img {
  padding-right: 7px;
  height: 18px;
}

#philosophy .philo-desc {
  color: #FFFFFF;
  /* font-family: 'RegularFonts',sans-serif; */

  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;
  /* width: 780px; */
  width: 75vw;
  margin: 30px auto;
}

.lol {
  font-size: 17px;
  color: #E6D3B3;
}

/* #philosophy .plan-det ul { padding-top: 10px; } */

#philosophy .plan-det li,
#philosophy .plan-det-col2 li {
  color: #FFFFFF;
  /* font-family: 'RegularFonts',sans-serif; */
  font-size: 13px;
  letter-spacing: 1.2px;
  line-height: 20px;
  margin: 25px 0 0;
  display: flex;
  /* align-items: center; */
  text-align: left;
}

#philosophy .plan-con {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 60px;

}

#philosophy .plan-con p {
  margin: 0;
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
}

@media (max-width: 767px) {
  #philosophy .philo-desc {
    color: #FFFFFF;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 1.3px;
    line-height: 28px;
    text-align: center;
    margin: 20px auto;
  }

  #philosophy .title-1,
  #philosophy .title-2 {
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 56px;
  
  }
  #philosophy .title-2 {
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 56px;
  
  }

  .lol {
    font-size: 19px;
    color: #E6D3B3;
  }

  #philosophy .plan-details {
    display: flex;
    flex-direction: column;
    padding: 0px;
    max-width: 1300px;
    margin: auto;
    font-family: 'Exo 2', sans-serif;
    font-weight: 100;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: left;
  }


  #philosophy .plan-det {
    /* max-width: 1300px; */
    /* display: flex;
        align-items: center;
        justify-content: center; */
    width: 90vw;

  }

  #philosophy .plan-det-col2 {
    width: 100vw;
    margin-left: 0px;
    margin: auto;
    padding-bottom: 50px;
    width: 90vw;


  }

  #philosophy .plan-det li,
  #philosophy .plan-det-col2 li {
    color: #FFFFFF;
    font-family: 'Exo 2', sans-serif;
    font-weight: 100;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 25px 0 0;
    display: flex;
    /* align-items: center; */
    text-align: left;
    padding-bottom: 70px;
  }

  #philosophy .plan-det img,
  #philosophy .plan-det-col2 img {
    padding-right: 5px;
    height: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  #philosophy .philo-desc {
    color: #FFFFFF;

    font-family: 'Exo 2', sans-serif;
    font-weight: 00;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 30px;
    text-align: center;
    margin: 30px 10px;
    width: 100vw;
  }

  #philosophy .plan-details {
    display: flex;
    flex-direction: column;
    padding: 0px;
    max-width: 1300px;
    margin: auto;

  }


  #philosophy .plan-det {
    /* max-width: 1300px; */
    /* display: flex;
      align-items: center;
      justify-content: center; */
    margin: 0 auto;
    width: 100vw;
  }

  #philosophy .plan-det-col2 {
    width: 100vw;
    margin-left: 0px;
    margin: auto;
  }

  #philosophy .plan-det li,
  #philosophy .plan-det-col2 li {
    color: #FFFFFF;

    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 25px;
    margin: 25px 0 0;
    display: flex;
    /* align-items: center; */
    text-align: left;
  }

  #philosophy .plan-det img,
  #philosophy .plan-det-col2 img {
    padding-right: 5px;
    height: 24px;
  }



}

@media (width < 328px) {

  #philosophy .plan-det img,
  #philosophy .plan-det-col2 img {
    padding-right: 5px;
    height: 18px;
  }

  #philosophy .philo-desc {
    color: #FFFFFF;
    font-family: 'Exo 2', sans-serif;
    font-weight: 100;
    font-size: 16px;
    letter-spacing: 1.3px;
    line-height: 30px;
    text-align: center;
    width: 95vw;
    margin: 30px auto;
  }


  #philosophy .title-1,
  #philosophy .title-2 {
    font-size: 25px;
    letter-spacing: 2px;
    line-height: 66px;
    font-family: 'RegularFonts', sans-serif;


  }

  #philosophy .plan-con {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 150px;
    width: 85vw;
    margin: auto;
    padding: 0px;

  }


  #philosophy .plan-con p {
    margin: 0;
    font-family: 'Exo 2', sans-serif;
    font-weight: 100;
  }

  #philosophy .title-1 {
    color: #FFFFFF;
    font-family: 'RegularFonts', sans-serif;
    padding-right: 10px;
  }

  #philosophy .title-2 {
    color: #E6D3B3;
    font-family: 'BlueL';
    font-size: 18px;
    font-weight: 600;
  }

  #philosophy .plan-det ul {
    margin: 0 auto;
    width: 100vw;
    padding: 0px;
  }

  #philosophy .plan-det-col2 ul {
    width: 100vw;
    padding: 0px;
    margin-left: 0px;
    margin: auto;
  }

  #philosophy .plan-det li {
    margin-top: 20px;

  }

  #philosophy .plan-det-col2 li {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
#contactus {
  /* height: 370px; */
  background-color: #1A1A1A;
  /* margin-top: 50px;  */
  overflow: hidden;
}

* {
  list-style: none;
}

#contactus a {
  color: inherit;
}

.xcx {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: auto;
  margin-top: 10px;
  font-weight: 700;
  padding: 16px;
  border-radius: 0px;
  font-size: 16px;
  text-align: center;
  color: black;
  background-color: #E6D3B3;
  font-weight: 700;
}


#contactus .foot-content {
  display: flex;
  /* display: inline-block; */
  /* text-align: center; */
  /* align-items: center;  */
  justify-content: space-between;
  /* height: 100%; */
  max-width: 1300px;
  margin: auto;
  padding: 50px 6.416666666666668VW !important;

}

#contactus .abt-title,
#contactus .menu-tile,
#contactus .con-title {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#contactus .con-phno,
#contactus .con-mail {
  color: #FFFFFF;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 25px;
  display: flex;
  /* align-items: center; */
}

#contactus .foot-icon {
  padding-right: 20px;
  width: 15px;
}

#contactus .foot-icon-phone {
  padding-right: 20px;
  width: 17px;
  height: 25px;
}

#contactus .con-addr {
  color: #FFFFFF;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 24px;
  margin: 20px 0;
  display: flex;
  align-items: flex-start;
}

#contactus .core-logo {
  margin-bottom: 0px;
  width: 150px;
  object-fit: cover;
}

#contactus .core-logo img {
  height: 100%;
  width: 100%;
  
}

#contactus .addr-desc {
  width: 180px;
}

#contactus .logo-block{
  display: flex;
  height: 60px;
  width: 70px;
}
#contactus .logo-block img{
  height: 30px; 
  width: 30px;
}

#contactus .abt-desc {
  width: 200px;
  opacity: 0.6;
  color: #FFFFFF;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
}

#contactus .col1,
.col2,
.col3,
.col4 {
  /* display: inline-block; */
  /* padding-left: 7.208333333333334VW; */
  text-align: left;
  vertical-align: top;
  padding-top: 50px;
  /* max-width: 300px; */
}

#contactus .menu li {
  color: #FFFFFF;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 37px;
  cursor: pointer;
}

#contactus .mas-title {
  color: #E6D3B3;
  font-size: 21px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 30px;
}

#contactus .copy-right {
  padding: 30px 10.416666666666668VW !important;
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
}

#contactus .contactus-copy {
  background-color: #040404;
}

#contactus .copy-title {
  opacity: 0.6;
  color: #FFFFFF;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 14px;
  /* text-align: center; */
}

.brand-title {
  font-weight: 600;
  color: #FFFFFF;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 14px;
  margin-top: 20px;
}

#contactus .social-med {
  display: flex;
  gap:16px;
  height: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  width:90px;
}

#contactus .aroma-logo {
  cursor: pointer;
}

#contactus .aroma-serv {
  margin-top: 18px;
  white-space: nowrap;
}

#contactus .serv-img {
  cursor: pointer;
}

#contactus .aroma-serv img {
  width: 50px;
  padding: 5px;
}

.menu ol {
  margin: 0px;
  padding: 0px;

}

/* #contactus .kiriyoga-logo {
    margin-top: 3px;
  } */

#contactus .aya-logo {
  margin-left: 20px;
}

.col4 {
  display: grid;
}

#quick-menu {
  height: 86px;
  width: 90px;
  position: fixed;
  bottom: 6.166666666666666VW;
  right: 0;
  z-index: 2;
  /* overflow: hidden;   */
  padding: 3px 0 3px 3px;
}


@media screen and (min-width: 320px) and (max-width: 1023px) {
  #contactus .foot-content {
    display: inline-block;
    text-align: center;
  }

  #contactus .col1,
  .col2,
  .col3,
  .col4 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    width: 60vw;
    margin: auto;
    text-align: left;
  }
  .col4{
    text-align: center;
  }
  .col4 img{
    height: 100%;
    width: 100%;
  }
  #contactus .aroma-serv img {
    width: 10.6458333333333335VW;
    padding: 5px;
  }

  #contactus .core-logo {
    width: 180px;
  }
  #contactus .core-logo img{
    width: 100%;
    height: 100%;
  }

  #contactus .social-med {
    height: 35px;
    gap: 0px;
    justify-content: left;
    align-items: center;
    text-align: left;
    margin: 0;
    width:auto;
  }
  #contactus .social-med img{
    height: 30px;
    width: 40px;
  }



  #quick-menu {
    height: 86px;
    width: 90px;
    position: fixed;
    bottom: 22.166666666666666VW;
    right: 0;
    z-index: 2;
    /* overflow: hidden;   */
    padding: 3px 0 3px 3px;
    display: none;
  }

}

#quick-menu .row-outer {
  height: 38px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#quick-menu .aromo-logo {
  height: 30px;
}

#quick-menu .row-outer:first-child {
  box-shadow: 0px 0px 30px 7px rgba(0, 0, 0, 0.1);
}

#quick-menu .buy-blk {
  background-color: #FFFFFF;
  cursor: pointer;
}

#quick-menu .buy-blk .cart-icon {
  background: url('../Images/aromothai_logo-small.svg') no-repeat -355px -15px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

#quick-menu .buy-blk p {
  color: #000000;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 18px;
  margin: 0;
  padding: 0;
}

#quick-menu .social-blk {
  margin-top: 0.5208333333333333VW;
  background-color: #EF2753;
}

#quick-menu .media-logo {
  cursor: pointer;
}
.Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 0px;
  height: 180px;
}

.m1 {
  font-family: "BlueLB";
  font-size: 47px;
  color: #756d6a;
  text-align: center;
  margin-bottom: 28px;
}

.Img1 img {
  height: 300px;
  width: 300px;
}

.btno {
  background-color: #756d6a;
  color: #e6d3b3;
  border: none;
  height: 47px;
  width: 230px;
  padding: 10px 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  border-radius: 33px;
  transition: 0.4s;
}

.btno:hover {
  color: white;
  font-weight: 0;
}

@media (max-width: 768px) {
  .btno {
    height: 45px;
    width: 180px;
    font-size: 16px;
  }

  .m1 {
    font-size: 40px;
    margin-top: 50px;
  }
}

.Main2 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 50px; */
  padding-top: 30px;
}

.m2 {
  font-family: "BlueLB";
  font-size: 40px;
  /* Adjust font size for responsiveness */
  font-weight: 600;
  /* Adjust font weight */
  text-align: center;
  color: #e6d3b3;
  width: 100vw;
  /* Center text */
  /* Adjust max-width as needed */
  padding: 20px;
  margin: 0 auto;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .m2 {
    font-size: 40px;
  }
}

.Main3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Main2Img {
  object-fit: cover;
}

.Img1 img {
  border-radius: 10px;
}

.m3 {
  /* font-family: 'RegularFonts', sans-serif; */

  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 17px;
  /* Adjust font size for responsiveness */
  /* Adjust font weight */
  text-align: center;
  /* Center text */
  max-width: 860px;
  /* Adjust max-width as needed */
  padding: 20px;
  margin: auto;
  line-height: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .m3 {
    font-size: 17px;
    margin-top: -19px;
  }
}

.Main2Img {
  height: auto;
  width: 400px;
  margin-bottom: 50px;
  background-color: #e6d3b3;
  box-shadow: 2px 2px 2px 2px rgb(98, 88, 88);
  box-sizing: border-box;
}

.Img1 {
  padding: 29px;
}

.m40 {
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 17px;
}

.m50 {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  margin-top: 17px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.main55 {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  height: auto;
  margin: auto;
  margin-top: 50px;
  gap: 50px;
}

@media (max-width: 767px) {
  .main55 {
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .Main2Img {
    width: 80vw;
    flex-direction: column;
    padding: 0px;
    display: flex;
    justify-content: space-around;
    object-fit: fill;
  }

  .m40 {
    /* font-family: 'BlueL'; */
    /* font-family: 'Comfortaa', cursive; */
    margin-top: 17px;
    font-size: 32px;
    font-weight: 700;
  }

  .m50 {
    font-size: 16px;
    letter-spacing: 0.3px;
  }

  .Img1 {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 30px;
    object-fit: fill;
  }
  .Img1 img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .btno {
    height: 45px;
    width: 250px;
    font-size: 16px;
  }

  .m1 {
    font-size: 45px;
  }

  .m2 {
    font-size: 36px;
    margin-top: 0%;
  }

  .main55 {
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .Main2Img {
    width: 80vw;
    flex-direction: column;
    padding: 0px;
    display: flex;
    justify-content: space-around;
    width: 50vw;
  }

  .m40 {
    font-size: 36px;
    font-weight: 600;
  }

  .m50 {
    font-size: 19px;
    line-height: 23px;
  }

  .Img1 {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 40px;
  }
}

@media (width < 328px) {
  .m1 {
    font-family: "BlueLB";
    font-size: 35px;
    color: #756d6a;
    text-align: center;
    margin-bottom: 25px;
  }

  .btno {
    height: 45px;
    width: 200px;
    font-size: 16px;
  }

  .m2 {
    font-size: 30px;
    margin-top: 0px;
  }

  .main55 {
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .Main2Img {
    width: 80vw;
    flex-direction: column;
    padding: 0px;
    display: flex;
    justify-content: space-around;
    width: 80vw;
  }

  .m40 {
    font-size: 30px;
    width: 80vw;
    text-align: left;
    font-weight: 600;
  }

  .Img1 {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}

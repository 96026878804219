.mn3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}

.mm {
  margin-top: 100px;
  width: 100vw;
  margin-bottom: 100px;
}

.mmmm {
  text-align: center;
  padding-top: 50px;
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
    font-size: 17px;
  font-weight: 500;
  /* line-height: 17px;
  letter-spacing: 0.8799999952316284px; */
  text-align: center;
}

.mmm {
  width: 100vw;
  padding: 0px;
  margin: auto;
}


.bbt {
  margin-top: 25px;
  width: 120.08984375px;
  height: 40px;
  border-radius: 18px;
  background-color: #756D6A;
  color: #ffff;
  border-style: none;
  
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
}

.un {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  height:auto;
  width: 100vw;
  object-fit: fill;
}

.image-containerx {
  /* position: relative; */
  text-align: center;
  background-color: #656564;

}

/* .image-container img {
  width: 100vw;
  height: 250px;
  opacity: 85%;
  object-fit: cover;

} */

.text-box1 {
  /* position: absolute; */
  top: 50%;
  left: 50%;
  margin: auto;
  /* transform: translate(-50%, -50%); */
  padding: 20px;
  border-radius: 10px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 20px;
  width: 100vw;

  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
}

.pop {

  font-family: 'BlueL';
  font-size: 28px;
  margin: auto;
  margin-top: 10px;

}

.buttons {
  margin-top: 15px;
  width: 100vw;
}

.buttons button {
  margin: 5px;
  color: rgb(96, 85, 85);
  border: none;
  background-color: #dfd6c7;
  cursor: pointer;
  width: 160px;
  font-size: 13px;
  height: 40px;
  font-weight: 600;
  border-radius: 0px;
}



/* Adjust button styles as needed */
@media (max-width: 768px) {
  .text-box1 {
    width: 100vw;
    height: auto;
    padding: 0px;
    font-size: 20px;
    
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
  }

  .un {
    height: auto;
    width: 100vw;
  }

  .pop {
    font-size: 23px;
    margin: 5px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 0px;
    margin: auto;
  }

  .buttons button {
    height: 35px;
    font-size: 11px;
    font-weight: 600;
    width: 120px;

  }

  .image-container img {
    height: 250px;
    width: 100%;
    object-fit: cover;
  }

  .mn3 {
    width: 100%;
  }
}

@media (width < 328px) {

  .buttons button {
    height: 35px;
    font-size: 10px;
    font-weight: 600;
    width: auto;
    padding:0px 20px;

  }

}
.tyc1 {
    height: auto;
    margin: 0px;
    padding: 0px;
}

.tyc3 {
    font-size: 35px;
    line-height: 90px;
    font-weight: 600;
    color: #756D6A;
    
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;

}

.tyc2 {
    height: 250px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 100vw;
}

.tyc4 {
    width: 80%;
    padding-bottom: 30px;
    margin: auto;
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
    font-size: 21px;
}

.tyc5 {
    text-align: center;

}

.tyc6 {
    font-size: 35px;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;}

.tyc7 {
    display: flex;
    width: 70vw;
    margin: auto;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 40px;
    align-items: center;
    padding: 0px;
}




.tyc8 {
    flex: 1;
    display: flex;
    justify-content: center;
    height: max-content;
    max-width: 550px;
}


.tyc10 {
    height: 250px;
    width: 350px;
    object-fit: fill;
    position: relative;
}

.tyc10 img {
    height: 100%;
    width: 100%;
}

.tyc10::before {
    content: "";
    position: absolute;
    left: -70px;
    top: -50px;
    width: 350px;
    height: 250px;
    border: solid 2px #82a5a9;
    z-index: -1;
}

.tyc9 {
    text-align: left;

}
.hr26{
    width: 150px;
    border: 3px solid black;
    margin: auto;
}
.tyc9 li {
    line-height: 30px;
    list-style: disc;
    font-size: 21px;
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}

.tyc11 p {
    line-height: 16px;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}

.tyc11 {
    height: 120px;
    align-items: center;
    padding: 10px;
}

.tyc12 {
    height: 600px;
    width: 100vw;
    text-align: center;
    padding-top: 90px;
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}


.tyc12 p {
    font-size: 27px;
    font-weight: 600;
    
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}



.tyc13 {
    height: auto;
    display: flex;
    justify-content: center;
    width: 100vw;
    align-items: center;

}

.tyc14 {
    height: 185px;
    padding-top: 40px;
    width: 100vw;
}

.tyc14 input {
    margin: 10px;
    height: 40px;
    font-size: 12px;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;    padding: 10px;
    width: 250px;
    border: 1px solid rgb(190, 186, 186);
}

.tyc13 textarea {
    width: 520px;
    font-size: 12px;
    padding: 10px;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;    border: 1px solid rgb(190, 186, 186);

}

.tyc13 button {
    border-radius: 0px;
    font-weight: 600;
    background-color: #6A6665;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;

}

@media (max-width: 767px) {

    .tyc3 {
        font-size: 25px;
        line-height: 40px;
        font-weight: 600;

    }

    .tyc1 {
        height: auto;
    }

    .tyc4 {
        width: 94%;
        padding-top: 5px;
        font-size: 21px;
    }

    .tyc2 {
        height: 150px;
        /* padding-top: 30px; */
    }

    .tyc5 {
        /* height: 700px; */
        text-align: center;

    }

    .tyc6 {
        font-size: 35px;
        line-height: 60px;
    }

    .tyc7 {
        flex-direction: column;
        width: 70vw;
        margin: auto;
        /* height: 600px; */
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
    }

    .tyc8 {
        flex: 1;
        display: flex;
        justify-content: center;
        height: max-content;
        padding-top: 80px;
    }


    .tyc10 {
        height: 200px;
        width: 250px;
        object-fit: fill;
        position: relative;
    }

    .tyc10 img {
        height: 100%;
        width: 100%;
    }

    .tyc10::before {
        content: "";
        position: absolute;
        left: -38px;
        top: -50px;
        width: 250px;
        height: 200px;
        border: solid 2px #82a5a9;
        z-index: -1;
    }

    .tyc11 {
        width: 100vw;
        margin: auto;

    }


    .tyc12 {
        height: 470px;
        width: 100vw;
        text-align: center;
        padding-top: 30px;

    }

    .tyc13 {
        height: auto;
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .tyc14 {
        height: 150px;
        padding-top: 40px;
        width: 100vw;
        margin: auto;
    }

    .tyc14 input {
        margin: 5px;
        height: 40px;
        font-size: 12px;
        padding: 10px;
        width: 46vw;
        border: 1px solid rgb(190, 186, 186);
    }

    .tyc13 textarea {
        width: 95vw;
        font-size: 12px;
        padding: 10px;
        border: 1px solid rgb(190, 186, 186);

    }

    .tyc13 button {
        border-radius: 0px;
        font-weight: 600;
        background-color: #6A6665;


    }



}

@media (width < 328px) {


    .tyc3 {
        font-size: 24px;
        line-height: 40px;
        font-weight: 600;

    }

    .tyc1 {
        height: auto;
    }

    .tyc4 {
        width: 94%;
        margin: auto;
        padding-top: 2px;
    }

    .tyc2 {
        height: 450px;
        /* padding-top: 30px; */
    }

    .tyc5 {
        height: auto;
        text-align: center;

    }

    .tyc6 {
        font-size: 35px;
        line-height: 60px;
    }

    .tyc7 {
        flex-direction: column;
        width: 70vw;
        margin: auto;
        height: 600px;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
    }

    .tyc8 {
        flex: 1;
        display: flex;
        justify-content: center;
        height: max-content;
        padding-top: 80px;
    }


    .tyc10 {
        height: 200px;
        width: 250px;
        object-fit: fill;
        position: relative;
    }

    .tyc10 img {
        height: 100%;
        width: 100%;
    }

    .tyc10::before {
        content: "";
        position: absolute;
        left: -28px;
        top: -50px;
        width: 250px;
        height: 190px;
        border: solid 2px #82a5a9;
        z-index: -1;
    }

    .tyc11 {
        width: 100vw;
        margin: auto;

    }


    .tyc12 {
        height: 470px;
        width: 100vw;
        text-align: center;
        padding-top: 30px;

    }

    .tyc13 {
        height: auto;
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .tyc14 {
        height: 150px;
        padding-top: 40px;
        width: 100vw;
        margin: auto;
    }

    .tyc14 input {
        margin: 5px;
        height: 40px;
        font-size: 12px;
        padding: 10px;
        width: 46vw;
        border: 1px solid rgb(190, 186, 186);
    }

    .tyc13 textarea {
        width: 95vw;
        font-size: 12px;
        padding: 10px;
        border: 1px solid rgb(190, 186, 186);

    }

    .tyc13 button {
        border-radius: 0px;
        font-weight: 600;
        background-color: #6A6665;


    }



}

@media screen and (max-width: 1300px) and (min-width: 768px) {
    .tyc3 {
        font-size: 29px;
        line-height: 40px;
        font-weight: 600;

    }

    .tyc1 {
        height: auto;
    }

    .tyc4 {
        width: 100vw;
        padding: 15px 20px;
        font-size: 21px;
    }

    .tyc2 {
        /* padding-top: 30px; */
    }

    .tyc5 {
        height: auto;
        text-align: center;

    }

    .tyc6 {
        font-size: 35px;
        line-height: 60px;
    }

    .tyc7 {
        flex-direction: row;
        width: 70vw;
        margin: auto;        
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
    }

    .tyc8 {
        flex: 1;
        display: flex;
        justify-content: center;
        height: max-content;
        padding-top: 0px;
    }


    .tyc10 {
        height: 200px;
        width: 250px;
        object-fit: fill;
        position: relative;
    }

    .tyc10 img {
        height: 100%;
        width: 100%;
    }

    .tyc10::before {
        content: "";
        position: absolute;
        left: -38px;
        top: -50px;
        width: 250px;
        height: 200px;
        border: solid 2px #82a5a9;
        z-index: -1;
    }

    .tyc11 {
        width: 100vw;
        margin: auto;

    }


    .tyc12 {
        height: auto;
        width: 100vw;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 60px;
    }

    .tyc12 p {
        font-size: 32px;
        font-weight: 600;
    }

    .tyc13 {
        height: auto;
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;

    }

    .tyc14 {
        height: 150px;
        padding-top: 0px;
        width: 100vw;
        margin: auto;
    }

    .tyc14 input {
        margin: 5px;
        height: 60px;
        font-size: 20px;
        padding: 10px;
        width: 46vw;
        border: 1px solid rgb(190, 186, 186);
    }

    .tyc13 textarea {
        width: 93vw;
        font-size: 20px;
        padding: 10px;
        border: 1px solid rgb(190, 186, 186);

    }

    .tyc13 button {
        border-radius: 0px;
        background-color: #6A6665;
        height: auto;
        font-size: 25px;
        width: auto;
        padding:20px 50px ;
        letter-spacing: 2px;
        font-weight: 0;

    }




}
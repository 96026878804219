.wo {
    width: 100vw;
    height: 100%;
    margin: auto;
    border: 2px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.toggle {
    margin: auto;
    text-align: center;
    font-size: 45px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    font-weight: bold;
    z-index: 9;
}

.link-pilates {
    color: black;
    text-decoration: none;
}


.link-pilates:hover {
    color: black;
}

.mnbv {
    border: 3px solid black;
    width: 130px;
    margin: auto;
    margin-bottom: 20px;
}


.uo2 p {
    font-weight: 600;
    line-height: 50px;
    font-size: 35px;
    letter-spacing: 2px;
    line-height: 50px;
    text-align: center;
    height: 60px;
    justify-content: center;
    width: auto;
    margin: auto;
    font-family: 'Exo 2', sans-serif;


}

.contentt-container {
    text-align: center;
    position: relative;
    top: 0%;
    left: 0%;
    margin: auto 0;
    color: aliceblue;
}

.writte1 {
    font-family: "BlueLB";
    font-size: 88px;
    font-weight: 700;
    color: #D7BD92;
}

.wo3 {

    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
}

.wo33 {
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    font-size: 15px;
}

.Wo1 {
    height: auto;
    width: 60vw;
    padding: 10px;
    margin-bottom: 0px;
    text-align: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.wo22 p,
.uo22 p {
    /* font-family: 'Exo 2',sans-serif; */
    color: #D7BD92;
    line-height: 50px;
    font-size: 50px;
    /* letter-spacing: 2px; */
    letter-spacing: 1.3px;
    font-weight: 600;
    line-height: 130px;
    text-align: center;
    justify-content: center;
    width: 60vw;
    margin: auto;
    font-family: 'BlueLB';

}

.wo2 p {
    font-family: 'Exo 2', sans-serif;
    /* font-family: 'Exo 2',sans-serif; */

    /* color: #D7BD92; */
    line-height: 50px;
    font-size: 35px;
    /* letter-spacing: 2px; */
    letter-spacing: 0px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    height: 60px;
    justify-content: center;
    width: 60vw;
    margin: auto;
}

.part2 {
    color: #E6D3B3;
    width: 100vw;
    height: 100%;
    margin: auto;
    border: 2px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.we {
    background-color: #6A6665;

}

.wo3 p {
    margin-top: 10px;

}

@media (max-width: 768px) {

    .writte1 {
        font-size: 70px;
        text-align: center;
        font-weight: 1200;
    }

    .contentt-container {
        text-align: center;
        align-items: center;
        justify-content: center;
        display: block;
        top: 0%;
        left: 0%;
        flex-direction: row;
        color: aliceblue;
        width: 100vw;
        position: relative;
    }



    .wo {
        height: auto;
        width: 100%;
        border: 2px;
        margin: auto;
        justify-content: center;
        text-align: center;
        padding-bottom: 50px;

    }

    .wo2 p {
        width: 100%;
        height: auto;
        border: 2px;
        margin: auto;
        justify-content: center;
        text-align: center;
        font-size: 24px;
        line-height: 40px;
        font-weight: 600;
    }

    .wo3 p {
        width: 100%;
        height: auto;
        font-size: 16px;
        text-align: center;

    }

    .Wo1 {
        height: auto;
        width: 90vw;
        padding: 10px;
        margin-bottom: 0px;
        text-align: center;
        margin: auto;
        margin-top: 100px;

    }

    .part2 {
        height: auto;
        width: 100%;
        border: 2px;
        margin: auto;
        justify-content: center;
        text-align: center;
        padding-bottom: 50px;

    }

    .uo2 p {
        width: 100%;
        height: auto;
        border: 2px;
        margin: auto;
        justify-content: center;
        text-align: center;
        font-size: 24px;
        font-weight: 600;

    }





}

@media (min-width: 768px) and (max-width: 1024px) {


    .writte1 {
        font-size: 70px;
        text-align: center;
        font-weight: 1200;
    }

    .contentt-container {
        text-align: center;
        position: relative;
        /* top: 20%;
        right: 25%; */
        left: 0%;
        align-items: center;
        justify-content: center;
        display: block;
        flex-direction: row;
        color: aliceblue;
        width: 100vw;
    }



    .wo {
        height: auto;
        width: 100%;
        border: 2px;
        margin: auto;
        justify-content: center;
        text-align: center;
        padding-bottom: 50px;

    }

    .wo2 p {
        width: 100%;
        height: auto;
        border: 2px;
        margin: auto;
        justify-content: center;
        text-align: center;
        font-size: 24px;
        line-height: 40px;
        font-weight: 600;
    }

    .wo3 p {
        width: 100%;
        height: auto;
        font-size: 16px;
        text-align: center;

    }

    .Wo1 {
        height: auto;
        width: 90vw;
        padding: 10px;
        margin-bottom: 0px;
        text-align: center;
        margin: auto;
        margin-top: 100px;

    }

    .part2 {
        height: auto;
        width: 100%;
        border: 2px;
        margin: auto;
        justify-content: center;
        text-align: center;
        padding-bottom: 50px;

    }

    .uo2 p {
        width: 100%;
        height: auto;
        border: 2px;
        margin: auto;
        justify-content: center;
        text-align: center;
        font-size: 24px;
        font-weight: 600;

    }



}
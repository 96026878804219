@import 'http://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800,900';

/* @font-face {
    font-family: 'RegularFonts', sans-serif;
    src: url(/src/Greycliff-CF-Sans-Font-Family/Demo_Fonts/Fontspring-DEMO-greycliffcf-regular.otf);
    src: url(/src/Greycliff-CF-Sans-Font-Family/Demo_Fonts/Fontspring-DEMO-greycliffcf-regular.otf);

} */

/* @font-face {
    font-family: 'Demibolds', sans-serif;
    src: url(/src//Greycliff-CF-Sans-Font-Family/Demo_Fonts/Fontspring-DEMO-greycliffcf-demibold.otf);
}

@font-face {
    font-family: 'BlueLB';
    src: url(/src/TextFonts.net_blue-liquid-font/Blue\ Liquid\ Bold.otf);
}

@font-face {
    font-family: 'BlueL';
    src: url(/src//TextFonts.net_blue-liquid-font//Blue\ Liquid.otf);
} */


.home-container {
    position: relative;
    width: 100vw;
    height: 101vh;
    overflow: hidden;
    background-image: url('../Images/hero-banner.jpg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/* .mainimgg {
    width: 100%;
    height: 101vh;
    object-fit: fill;
    object-position: bottom;
} */

.nav {
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: .5rem;
    background-color: #686463;
    height: 90px;
    width: 100%;
}

.navv {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.navv li {
    margin-right: 1.2rem;
}

.no-underline {
    text-decoration: none;
    color: aliceblue;
    font-weight: 500;

}

.navv li:after {
    background-color: white;
    content: '';
    display: block;
    height: .1em;
    margin-left: calc(var(--link-underline-padding) * -1);
    margin-top: .2em;
    transition: width .5s;
    width: 0;
}

.navv li:hover:after {
    width: calc(100% + var(--link-underline-padding) * 2);
}
.iii {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: auto;

}

.content-container {
    text-align: center;
    position: relative;
    width: 100vw;
    color: aliceblue;
    top: 0%;
    left: 0%;
    
}

.write1 {
    font-family: 'BlueLB';
    font-size: 88px;
    font-weight: 700;
}

.write2 {
    font-family: 'RegularFonts', sans-serif;
    font-size: 23px;

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .write1 {
        font-size: 60px;
        text-align: center;
        font-weight: 1200;
    }

    .content-container {
        text-align: center;
        /* position: absolute; */
        /* top: 20%;
        right: 25%; */
        left: 0%;
        top: 0%;
        color: aliceblue;
        width: 100vw;

        /* margin-top: -22px; */
    }

    .mainimgg {
        object-fit: cover;
    }

    .write2 {

        font-size: 19px;
        text-align: center;
        font-weight: 400;

    }

    .write1,
    .write2 {
        margin: 0 60px;
        display: block;
        text-align: center;
    }
}



.navv li a:hover,
.navv li a:active {
    color: white;
    text-decoration: none;
}

.mobile-menu {
    display: none;
}

.gaft2 {
    display: none;
}


.active {
    color: black;
}

.gaft {
    background-color: #F1F0F0;
    border-radius: 0px;
    color: #756D6A;
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    .write1 {
        font-size: 40px;
        text-align: center;
        font-weight: 1200;
    }

    .content-container {
        text-align: center;
        /* position: absolute; */
        /* top: 20%;
        right: 25%; */
        left: 0%;
        top: 0%;
        color: aliceblue;
        width: 100vw;
        position: relative;

        /* margin-top: -22px; */
    }


}

@media (width < 328px) {

    .write1 {
        font-size: 45px;
        text-align: center;
        font-weight: 1200;
    }

    .content-container {
        text-align: center;
        /* position: absolute; */
        /* top: 20%;
        right: 25%; */
        left: 0%;
        top: 40%;
        color: aliceblue;
        width: 100vw;
        position: relative;

        /* margin-top: -22px; */
    }





}
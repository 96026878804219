#schedule {
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  max-width: 1300px;
  font-weight: 600;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#location {
  display: flex;
  padding: 10px;
}

#schedule .heading-row,
#schedule .description-row {
  text-align: center;
}

::-webkit-scrollbar {
  width: 0px;
}

#schedule .table-content::-webkit-scrollbar {
  width: 5px;
}

#schedule .table-content::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  max-height: 20px;
}

#schedule .table-content::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 20px;
  height: 10px;
  max-height: 20px;
}

#schedule .table-content:hover::-webkit-scrollbar-thumb {
  /* background: #888; */
  /* width: 8px; */
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
}

#schedule .description {
  color: #000000;
  font-family: "RegularFonts", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 23px;
  text-align: center;
  /* padding: 3% 15%; */
  width: 780px;
  margin: 30px auto;
}

#schedule .toggle-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "BlueL";
}

#schedule .container {
  width: 100%;
  overflow: hidden;
}

.table-container {
  display: flex;
  justify-content: center;
}

#schedule .table-content {
  overflow-y: scroll;
  max-height: 400px;
}

#schedule .table-header {
  font-size: 16px;
}

#schedule .toggle,
#schedule .table-header {
  display: flex;
}

#schedule .table-header,
#schedule .color-time {
  background: #e6d3b3;
}

#schedule .table-content-row {
  display: flex;
  font-size: 12px;
}

#schedule .table-block,
#schedule .head-block,
#schedule .time-block {
  width: 150px;
}

#schedule .table-block,
#schedule .head-block {
  overflow-wrap: break-word;
  text-align: center;
}

#schedule .table-block {
  cursor: pointer;
  transition: background-color 0.1s ease-in;
}

#schedule .head-block {
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
}

#schedule .week-day {
  opacity: 0.6;
  color: #000000;
  font-family: "RegularFonts", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
}

#schedule .time-block {
  overflow-wrap: break-word;
  text-align: center;
  background: #e6d3b3;
}

#schedule .text-div {
  margin: 20px 14px;
  text-transform: capitalize;
}

#schedule .square-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 76px;
  width: 150px;
}

#schedule .date-label {
  background: #e6d3b3;
}

#schedule .even-row .odd-block,
#schedule .odd-row .even-block {
  background: #fafafa;
}

#schedule .book-div {
  display: none;
  background: #ffff;
  padding: 14px 18px;
  font-size: 12px;
  color: black;
  box-shadow: -1px -1px 8px 0 rgba(0, 0, 0, 0.1),
    1px 1px 8px 0 rgba(0, 0, 0, 0.1);
  min-height: 76px;
  justify-content: center;
  align-items: center;
}

.select-container {
  margin: 20px;
  margin-left: 120px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.dropdown-label {
  font-size: 20px;
  margin-bottom: 8px;
  color: #333;
}

.dropdown-select {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  outline: none;
}

.dropdown-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}


#schedule .table-header,
#schedule .table-content {
  min-width: 1000px;
}

/* #schedule .table-block:hover {
  background-color: #6a6665 !important;
} */

#schedule .table-block2:hover {
  background-color: #6a6665 !important;
}

#schedule .table-block2:hover .text-div {
  display: none;
}

#schedule .table-block2:hover .book-div {
  display: flex;
}

#schedule .pri-ses {
  opacity: 0.9;
  color: #000000;
  font-family: "BlureLB";
  font-size: 14px;
  letter-spacing: 0.88px;
  line-height: 17px;
  text-align: center;
  margin-top: 50px;
}

.mn3 p {
  width: 59vw;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
}

/* @media screen and (max-width: 320px) {
    #schedule .container {
        max-width: 300px;
    }
  } */

.head {
  font-size: 35px;
  text-align: center;
  font-weight: 600;
  font-family: "RegularFonts", sans-serif;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  #schedule .schedule-table {
    overflow: scroll;
  }

  
}

.head {
  font-size: 35px;
  text-align: center;
  font-weight: 600;
}

.ere {
  width: 10vw;
  border: 2px solid black;
  color: #6a6665;
  margin: auto;
}

@media screen and (max-width: 600px) {
  #schedule .container {
    max-width: 550px;
  }

  #schedule .schedule-table {
    margin: 0 10px;
  }

  #schedule .description {
    width: 80.25vw;
  }
}

@media screen and (max-width: 550px) {
  #schedule .container {
    max-width: 500px;
  }

  #schedule .schedule-table {
    margin: 0 10px;
  }
}

@media screen and (max-width: 500px) {
  #schedule .container {
    max-width: 450px;
  }

  #schedule .schedule-table {
    margin: 0 10px;
  }
}

@media screen and (max-width: 450px) {
  #schedule .container {
    max-width: 400px;
  }

  #schedule .schedule-table {
    margin: 0 10px;
  }
}

@media screen and (max-width: 375px) {
  #schedule .container {
    max-width: 350px;
  }

  #schedule .schedule-table {
    margin: 0 10px;
  }

  .select-container {
    margin: 10px;
    padding: 0 10px;
  }

  .dropdown-label {
    font-size: 18px;
  }

  .dropdown-select {
    padding: 8px 12px;
    font-size: 14px;
  }
  
}

@media screen and (max-width: 320px) {
  #schedule .container {
    max-width: 300px;
  }

  #schedule .schedule-table {
    margin: 0 10px;
  }
}

.Pop {
  text-align: center;
  margin-top: 50px;
}

.Pop button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.overlay-schedule {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2334499933333333333;
}

.overlay-content-schedule {
  background-color: white;
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.3);
  position: relative;
  height: auto;
  width: 850px;
  z-index: 11;
}

.overlay-content h3 {
  color: #6a6665;
  font-weight: 600;
  font-size: 35px;
}

.nbn p {
  justify-content: center;
  display: flex;
  padding-top: 7px;
  font-size: 18px;
  align-items: center;
  gap: 5px;
}

.body-no-scroll {
  overflow: hidden;
}

.bnb {
  padding-top: 10px;
  font-size: 15px;
  text-align: justify;
}

.vbv {
  font-weight: 600;
  display: none;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  padding: 5px 10px;
  /* background-color: #ff0000; */
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 0;
  z-index: 2334499933333333333;
}

/* .close-button:hover {
  background-color: #cc0000;
} */

.content {
  height: 300px;
  width: 300px;
}

@media screen and (max-width: 768px) {
  .overlay-content {
    background-color: white;
    padding: 70px 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    height: auto;
    width: 100vw;
  }

  .ere {
    width: 40vw;
    border: 2px solid #6a6665;
    color: #6a6665;
    margin: auto;
  }

  .nbn p {
    justify-content: center;
    padding-top: 20px;
    flex-direction: row;
  }

  .mn3 p {
    width: 90vw;
  }
}


@font-face {
    font-family: 'RegularFonts', sans-serif;
    /* src: url(/src/DGreycliff-CF-Sans-Font-Family/Demo_Fonts/Fontspring-DEMO-greycliffcf-regular.otf); */
        src: url(/src/Greycliff-CF-Sans-Font-Family/Demo_Fonts/Fontspring-DEMO-greycliffcf-regular.otf);

}

@font-face {
    font-family: 'Demibolds', sans-serif;
    src: url(/src//Greycliff-CF-Sans-Font-Family/Demo_Fonts/Fontspring-DEMO-greycliffcf-demibold.otf);
}

@font-face {
    font-family: 'BlueLB';
    src: url(/src/TextFonts.net_blue-liquid-font/Blue\ Liquid\ Bold.otf);
}

@font-face {
    font-family: 'BlueL';
    src: url(/src//TextFonts.net_blue-liquid-font//Blue\ Liquid.otf);
}
*{
    scroll-behavior: smooth;
    
    font-family: 'Exo 2',sans-serif;
    font-weight: 700;
}
.WhatsappContainer {
    text-align: center;
    padding: 5px;
    margin: auto;
    position: fixed;
    background-color: #25d366;
    height: 58px;
    width: 60px;
    z-index: 100;
    border-radius: 47%;
    right: 20px;
    padding: 7px;
    top:90%;
}

.WhatsappLink {
    font-size: 15px;
    text-align: center; /* Adjust the font size to make the icon bigger */
    color: white; 
    
}
@media (max-width: 768px) {
    .WhatsappContainer {
        text-align: center;
        padding: 5px;
        margin: auto;
        position: fixed;
        background-color: #25d366;
        height: 50px;
        width: 50px;
        z-index: 100;
        border-radius: 50%;
        right: 20px;
        padding: 10px;
        top:90%;
    }
    .WhatsappLink {
        font-size: 10px;
        text-align: center; /* Adjust the font size to make the icon bigger */
        color: white; 
        
    }


}
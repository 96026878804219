.noida1 {
    height: 1700px;
    margin: auto;
    width: 85vw;
    padding: 100px 0px;

}

.noida11 {
    width: 200px;
    border: 2px solid black;
    margin: auto;
    margin-top: 8px;
}

.noida {
    background-color: #E6D3B3;
}

.noida1 h4 {
    text-align: center;
    font-size: 30px;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;

}

.noida2 {
    width: 85vw;
    padding: 50px 0px;
    height: 605px;
}


.noida2 iframe {
    width: 85vw;
    height: 500px;


}

.noida3 h3 {
    font-size: 24px;
    color: #424140;

    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
}

.noida3 p {
    font-size: 16px;
    color: #424140;

    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
}


.noida4 {
    height: 800px;
    /* padding: 40px 0px; */
    width: 1300px;
    margin: auto;

}

.noida4 h4 {
    line-height: 40px;
}

.noida5 {
    height: 260px;
    display: flex;
    justify-content: space-between;
    width: 1300px;

}

.noida23 h4 {
    line-height: 40px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.noida6,
.noida7,
.noida8,
.noida9,
.noida10 {
    height: 250px;
    width: 250px;
    /* border: 1px solid black; */
}

.noida6 img,
.noida7 img,
.noida8 img,
.noida9 img,
.noida10 img {
    height: 250px;
    width: 250px;
}


@media (max-width: 768px) {
    .noida1 {
        height: 1950px;
        margin: auto;
        width: 100vw;
        padding: 0px;
        /* margin: 0px; */

    }

    .noida1 h4 {
        text-align: center;
        font-size: 27px;

    }

    .noida2 {
        width: 90vw;
        padding: 30px 0px;
        margin: auto;
        height: 600px;
    }

    .noida2 iframe {
        width: 90vw;
        height: 500px;
    }

    .noida4 {
        height: 200px;
        padding: 0px 0px;
        width: 95vw;
        margin: auto;
        flex-direction: row;
        display: flex;
    }

    .noida23 h4 {
        line-height: 40px;
        padding: 40px 0px;
    }

    .noida5 {
        height: 850px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0px;
        margin: auto;



    }

    .noida6,
    .noida7,
    .noida8,
    .noida9,
    .noida10 {
        height: 46vw;
        width: 46vw;
        /* border: 1px solid black; */
    }

    .noida6 img,
    .noida7 img,
    .noida8 img,
    .noida9 img,
    .noida10 img {
        height: 48vw;
        width: 45vw;
    }

    .noida23 {
        height: 100px;
    }

    .noida3 {
        width: 90vw;
        margin: auto;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .noida1 {
        height: 1950px;
        margin: auto;
        width: 100vw;
        padding: 0px;
        /* margin: 0px; */

    }

    .noida1 h4 {
        text-align: center;
        font-size: 27px;

    }

    .noida2 {
        width: 90vw;
        padding: 30px 0px;
        margin: auto;
        height: 600px;
    }

    .noida2 iframe {
        width: 90vw;
        height: 500px;
    }

    .noida4 {
        height: 200px;
        padding: 0px 0px;
        width: 95vw;
        margin: auto;
        flex-direction: row;
        display: flex;
    }

    .noida23 h4 {
        line-height: 40px;
        padding: 40px 0px;
    }

    .noida5 {
        height: 850px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0px;
        margin: auto;
    }

    .noida6,
    .noida7,
    .noida8,
    .noida9,
    .noida10 {
        height: 40vw;
        width: 46vw;
        margin-bottom: 70px;
        /* border: 1px solid black; */
    }

    .noida6 img,
    .noida7 img,
    .noida8 img,
    .noida9 img,
    .noida10 img {
        height: 48vw;
        width: 45vw;
    }

    .noida23 {
        height: 100px;
    }

    .noida3 {
        width: 90vw;
        margin: auto;
    }

    .noida {
        background-color: #E6D3B3;
        height: 3000px;
    }

}
 .containerp{
    display: flex;
    justify-content: center;
    width: 100vw;
    /* max-width: 1300px; */
    padding: 0 10.416666666666668VW !important;
 }
 .privacy-blk {
    padding-top: 120px;
    text-align: justify;
}
.privacy-blk h3{
    color: #d7bd92;
}
.privacy-blk .list {
    padding-left: 5%;
}


.privacy-blk h3{
    margin-bottom: 80px;
    font-family: 'BlueLB';
    color: #0c0904;
    font-size: 50px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 2px;
    text-align: center;
}

.privacy-blk p {
    color: #000000;
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 30px;
}
.privacy-blk h4{
    margin:20px 0px;
    font-weight: 600;

}
.privacypolicy{
    margin-bottom: 60px;
}
@media (min-width: 320px) and (max-width: 1024px) {

    .privacy-blk h3{
        margin-bottom: 40px;
        font-family: 'BlueLB';
        color: #0c0904;
        font-size: 35px;
        font-weight: 600;
        letter-spacing: 2px;
        text-align: center;
    }
}
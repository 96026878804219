

.image-container {
    margin: .5%;
    height: 250px;
    width: 250px;
}
.ui1{
    width: 90vw;
}
.ui2{
    height:100%;
    width: 90vw;
    padding: 0;
}
.ui3{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 90vw;
}

.ui3 img{
    height: 250px;
    width: 250px;
}
.image-container img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.overl{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);


}

.carousel {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vw;
    height: 80vh;
    background-color: #E6D3B3;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
z-index: 595959595995595959;
        /* Add this line for relative positioning */
}


.carousel img {
    width: 100%;
    height: 100%;
    object-fit: fill;

}


.fa-solid{
    background-color: #E6D3B3;
    font-size: 40px;
}
.carousel button {
    margin: 10px;
    display: inline-block;
    height: auto;
    width: auto;
    padding: 4px;
    background-color: #E6D3B3;
    color: #654321;


}

.close-button {
    position: absolute;
    top: 10px;
    right: 5px;
    color: #654321;
}

@media (max-width: 768px) {


    .image-container {
        width: 100%;
        margin: 1%;
        border: 0;
    }
    .ui1{
        width: 95vw;
        margin: auto;
        height: 1100px;
        padding: 10px 0px;
    }
    .ui2{
        height:900px;
        width: 95vw;
        display: flex;
        flex-direction: row;
        gap: 5px;

    }
    .ui3{
        height: 46vw;
        width: 46vw;
        flex-direction: column;
        border: 0px;
    }
    .ui3 img{
        height: 48vw;
        width: 45vw;
        object-fit: cover;

    }
    
    
    .carousel img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    
    .carousel {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 93vw;
        height: 70vh;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        text-align: center;
        padding: 40px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    
            /* Add this line for relative positioning */
    }
    .fa-solid{
        font-size: 50px;
        padding: 3px;
    }
    .carousel button {
        margin: 5px;
        display: inline-block;
        height: auto;
        width: auto;
        padding: 0px;
        background-color: unset;
        color: black;

    }
    
    .close-button {
        position: absolute;
        top: -15px;
        right: 0px;
        color: black;
        font-size: 42px;
    }
    

}

@media (min-width: 768px) and (max-width: 1024px) {


    .image-container {
        width: 100%;
        margin: 1%;
    }
    .ui1{
        width: 95vw;
        margin: auto;
        height: 1100px;
        padding: 10px 0px;
    }
    .ui2{
        height:900px;
        width: 95vw;
        display: flex;
        flex-direction: row;
        gap: 5px;

    }
    .ui3{
        height: 46vw;
        width: 46vw;
        flex-direction: column;
        border: 0px;
    }
    .ui3 img{
        height: 48vw;
        width: 45vw;
        object-fit: cover;

    }
    
    
    .carousel img {
        width: 100%;
        height: 100%;
    }
    
    .carousel {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90vw;
        height: 50vh;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        text-align: center;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    
            /* Add this line for relative positioning */
    }
    
    .carousel button {
        margin: 10px;
        display: inline-block;
    }
    
    .close-button {
        position: absolute;
        top: 10px;
        right: 3px;
    }
    




}
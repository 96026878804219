.ptt {
    position: relative;
    height: 800px;
    width: 100vw;
    overflow: hidden;
    z-index: 0;
    

}

.ptt img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
}
/* Hero.css */
.Hero2 {
    position: relative;
    height: 709px;
    width: 100vw;
    overflow: hidden;

}

.Hero2 .hero-image {
    object-fit: fill;
    width: 100%;
    height: 120%;
    position: absolute;
    top: 40px;
    left: 0;
}


@media (max-width: 768px) {

    .Hero2 {
        position: relative;
        height: 400px;
        width: 100vw;
        overflow: hidden;

    }
    
    .Hero2 .hero-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 40;
        left: 0;
    }
    
    .writte1 {
        font-size: 35px;
        letter-spacing: 1px;
    }



}
@media (min-width: 768px) and (max-width: 1024px) {


    .Hero2 {
        position: relative;
        height: 400px;
        width: 100vw;
        overflow: hidden;

    }
    
    .Hero2 .hero-image {
        object-fit: fill;
        width: 100%;
        height: 130%;
        position: absolute;
        top: 40;
        left: 0;
    }
    
    .sticky-navbar {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 10; /* Make sure the z-index is higher than that of the image */
    }







}
.cuntent-container {
    position: absolute;
    text-align: center;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%); 
    color: aliceblue;
    box-sizing: border-box;
    z-index: 1;

}

.writ1 {
    font-family: 'BlueLB';
    font-size: 70px;
    letter-spacing: 4px;
    font-weight: 700;
    color: #D7BD92;
    z-index: 1;
}

@media (max-width: 767px) {
    .ptt {
        height: auto;
        width: 100vw;
        margin: 0;
        z-index: -1;
        padding: 0;
    }

    .ptt img {
        height: 100%;
        width: 100%;
        object-fit: fill;
    }

    .writ1 {
        font-size: 34px;
        text-align: center;
        font-weight: 600;
        letter-spacing: 2px;
        font-family: 'BlueLB';
        z-index: 1;

    }

    .cuntent-container {
        text-align: center;
        top:45%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        color: white;
        width: 100vw;
        z-index: 1;

    }
}


@media (width < 328px) {


    .cuntent-container {
        text-align: center;
        top:60%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        color: white;
        width: 100vw;
        z-index: 1;

    }

    .writ1 {
        font-size: 28px;
        text-align: center;
        font-weight: 600;
        letter-spacing: 2px;
        font-family: 'BlueLB';
        z-index: 0;


    }

}
@media screen and (min-width: 768px) and (max-width: 1024px) {



    .cuntent-container {
        text-align: center;
        top:50%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        color: white;
        width: 100vw;
    }
}
.blo1 {
    height: auto;
    width: 70vw;
    padding: 20px 80px;
    margin:40px auto;
    background-color: #e4e4e3;
}

.blo2 img {
    height: 350px;
    width: 60vw;
    padding: 30px 0px;
}

.blo3 {
    font-size: 30px;

}

.blo4 {
    font-size: 20px;
}

.blo5 {
    font-size: 30px;
    padding: 30px 0px;
}

.blo6 {
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
}

.blo7 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 40vw;
    margin: auto;

}

.blo7 img {
    height: auto;
    width: 200px;
}

.blo8 {
    flex: 1;
    padding: 0px 50px;
}

.blo9 {
    flex: 1;
}

.blo16 {
    font-weight: 600;
    text-align: justify;

    font-size: 18px;
}

.blo17 {
    font-family: 'Exo 2', sans-serif;
    text-align: justify;
    width: 240px;
}

.blo10 {

    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    line-height: 28px;
}

.blo12 li {
    list-style: disc;
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    line-height: 28px;

}

.blo13 {
    padding: 10px;
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    line-height: 28px;
}

.blo14 {
    font-size: 30px;
    font-style: oblique;
    font-weight: 600;
}

.blo15 {
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    line-height: 28px;
    padding: 10px;
}

@media (max-width: 1023px) {
    .blo1 {
        height: auto;
        width: 90vw;
        margin:40px auto;
        padding: 40px 20px;
        background-color: #e4e4e3;
    }

    .blo2 img {
        height: 300px;
        width: 80vw;
        padding: 0px;
    }

    .blo3 {
        font-size: 30px;

    }

    .blo4 {
        font-size: 20px;
    }

    .blo5 {
        font-size: 25px;
        padding: 0px 0px;
        text-align:start;
        word-spacing: 0;
    }

    .blo6 {
        font-size: 16px;
    }

    .blo7 {
        justify-content: space-around;
        flex-direction: column-reverse;
        background-color: rgb(216, 220, 222);
        align-items: center;
        text-align: center;
        width: 80vw;
        margin:10px auto;
        padding: 10px 0px;
    }

    .blo7 img {
        height: auto;
        width: 60vw;
        padding-bottom: 30px;
        border: none;
    }

    .blo8 {
        /* flex: 1; */
        width: 80vw;
        padding: 0px 0px;
        object-fit: fill;
    }

    .blo9 {
        /* flex: 1; */
        width: 80vw;

        padding: 10px 0px;
    }

    .blo16 {
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        /* padding-top: 60px; */
    }

    .blo17 {
        text-align: center;
        width: 80vw;
        font-size: 15px;
        /* height: 180px; */
    }

    .blo10 {

        font-size: 16px;
        line-height: 28px;
    }

    .blo12 li {
        list-style: disc;
        font-size: 16px;
        line-height: 28px;

    }

    .blo13 {
        padding: 10px;
        font-size: 15px;
        line-height: 28px;
    }

    .blo14 {
        font-size: 30px;
        font-style: oblique;
        font-weight: 600;
        margin: auto;
        padding:0px 10px;
    }

    .blo15 {
        font-size: 16px;
        line-height: 28px;
        padding: 10px;
    }




}
@media (width < 328px) {

    .blo5 {
        font-size: 22px;
        padding: 0px 0px;
        text-align:start;
        word-spacing: 0;
    }


}
/* .ptm {
    position: relative;
    height: 800px;
    width: 100vw;
    overflow: hidden;
}

.ptm img {
    height: 100%;
    width: 100%;
    object-fit: fill;
} */

.cuntent-containe {
  position: relative;
  text-align: center;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: aliceblue;
  box-sizing: border-box;
  z-index: 0;
  /* Set a higher z-index to ensure it's on top of the image */
}

.overlayx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 709px;
  background-color: rgba(0, 0, 0, 0.2);
  /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.writ {
  font-family: "BlueLB";
  font-size: 88px;
  font-weight: 700;
  color: #d7bd92;
}

.ourstudio1 {
  height: 1250px;
  width: 100vw;
}

.ourstudio2 {
  display: flex;
  flex-wrap: wrap;
  background-color: #e6d3b3;
  justify-content: center;
  /* gap: 40px; */
  align-items: center;
  height: 1250px;
}

.werr {
  width: 20vw;
  height: 570px;
  padding: 10px;
  margin: 0 20px;
  border-radius: 10px;
  max-width: 550px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  text-align: center;
}

.werr img {
  height: 300px;
  padding: 10px;
}

.werr button {
  background-color: #e6d3b3;
  color: #424140;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  font-family: "Exo 2", sans-serif;
}

@media (max-width: 767px) {
  .ptm {
    height: 350px;
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .ptm img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .writ {
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    font-family: "BlueL";
  }

  .cuntent-containe {
    color: white;
    height: auto;
    width: 100vw;
    left: 0%;
    top: 0%;
  }

  .overlayx {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100vw;
    height: 350px;
    background-color: rgba(0, 0, 0, 0.3);
    /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ourstudio1 {
    height: auto;
    width: 100vw;
    padding: 25px 0;
    background-color: #e6d3b3;
  }

  .ourstudio2 {
    background-color: #e6d3b3;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    height: auto;
    gap: 25px;
  }

  .werr {
    width: 70vw;
    height: 510px;
    padding: 0px;
    border-radius: 10px;
  }

  .werr img {
    height: 250px;
    padding: 20px;
  }

  .werr button {
    background-color: #e6d3b3;
    color: #424140;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 1300px) and (min-width: 768px) {
  .ptm {
    height: 450px;
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .ptm img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .writ {
    font-size: 65px;
    text-align: center;
    font-weight: 600;
    font-family: "BlueLB";
  }

  .cuntent-containe {
    text-align: center;
    top: 0%;
    color: white;
    width: 100vw;
  }
  .overlayx {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100vw;
    height: 370px;
    background-color: rgba(0, 0, 0, 0.3);
    /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ourstudio1 {
    height: 1600px;
    width: 100vw;
  }

  .ourstudio2 {
    background-color: #e6d3b3;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* flex-direction: column; */
    height: 1600px;
  }

  .werr {
    width: 32vw;
    height: auto;
    padding: 0px;
    border-radius: 10px;
  }

  .werr img {
    height: 250px;
    padding: 20px;
  }

  .werr button {
    background-color: #e6d3b3;
    color: #424140;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
  }
}

@media (width < 328px) {
  .ptm {
    height: 450px;
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .ptm img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .writ {
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    font-family: "BlueLB";
  }

  .cuntent-containe {
    text-align: center;
    top: 0%;
    color: white;
    width: 100vw;
  }
  .overlayx {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100vw;
    height: 370px;
    background-color: rgba(0, 0, 0, 0.3);
    /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ourstudio1 {
    height: auto;
    width: 100vw;
  }

  .ourstudio2 {
    background-color: #e6d3b3;
    justify-content: center;
    gap: 50px;
    align-items: center;
    /* flex-direction: column; */
    /* height: 1950px; */
  }

  .werr {
    width: 80vw;
    height: auto;
    padding: 0px;
    border-radius: 10px;
  }

  .werr img {
    height: 250px;
    padding: 20px;
  }

  .werr button {
    background-color: #e6d3b3;
    color: #424140;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
  }
}

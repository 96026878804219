.faq1 {
    padding: 80px;
    word-spacing: 1px;
    margin: auto;
    width: 100vw;
    background-color: #E6D3B3;
    color: #756D6A;


}

.faq1 p {
    text-align: center;
    font-size: 17px;
    font-family: 'Exo 2',sans-serif;
}

.container h1 {
    text-align: center;
    font-size: 60px;
    letter-spacing: 3px;
    font-family: 'BlueLB';
    /* margin: 30px 0px; */
}


.faqaccor {
    width: 65vw;
    margin: auto;
}

.faqaccor h3 {
    font-size: 22px;
    color: #756D6A;
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}

.faqaccor p {
    font-size: 17px;
    font-weight: 0;
    color: #756D6A;
    text-align: justify;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
    /* font-family: 'RegularFonts',sans-serif; */

}

.container {
    margin: auto;
    padding: 0px;
    margin: 0px;

}



@media (max-width: 768px) {


    .faq1 {
        padding: 40px 0px;
        word-spacing: 1px;

    }

    .container h1 {
        font-size: 42px;

    }


    .faqaccor {
        width: 90vw;
        margin: auto;
    }

    .faqaccor h3 {
        font-size: 20px;
        color: #756D6A;
    }

    .faqaccor p {
        font-size: 18px;
        font-weight: 0;
        color: #756D6A;
        text-align: justify;

    }

    



}
.Amain {
    width: 100vw;
    margin-top: 0px;
    height: 900px;
}

.Amain2 {
    width: 100vw;
    height: 180px;
    margin: auto;
    padding-top: 45px;
}

.ap {
    font-family: 'Exo 2', sans-serif;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
}

.ap2 {
    font-family: 'BlueLB';
    color: #D7BD92;
    font-size: 35px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0.05em;
    text-align: center;
}

.Amain3 {
    display: table-cell;
    vertical-align: middle;
    width: 100vw;
    height: 140px;
    text-align: center;
    margin: auto;
    /* padding-top: 20px; */

}

.ap3 {
    font-family: 'Exo 2', sans-serif;

    /* font-family: 'RegularFonts', sans-serif; */
    font-size: 17px;
    font-weight: 600;
    line-height:30px;
    width: 46vw;
    margin: auto;
    text-align:center;
}

.ap4 {
    /* font-family: 'BlueLB'; */
    font-family: 'Exo 2', sans-serif;

    font-size: 35px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    color: #6A6665;

}

.ap6 {
    /* width: 149.64px;
    height: 48px; */
    font-weight: 600;
    border-radius: 4px;
    margin: 0px;
    color: aliceblue;
    border-style: none;
    /* margin: 40px 60px; */
    text-align: center;
    background-color: #756D6A;
    font-family: 'Exo 2', sans-serif;

}

.Amain5 {
    width: 360.46630859375px;
    height: 460px;
    max-width: 402px;
    background-color: #909090;
    box-shadow: 4px 4px rgb(62, 36, 36);
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Amain4 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 130px; */
    color: white;
    height: 500px;
    width: 80vw;
    gap: 30px;
    margin: auto;
}

.ap5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 1px;
    text-align: justify;
    /* font-family: 'RegularFonts', sans-serif; */
    font-family: 'Exo 2', sans-serif;
    color: white;
    margin: auto;

}

.overlayxy {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100vw;
    height: 629px;
    background-color: rgba(0, 0, 0, 0.4);
    /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
}




.ap8 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: .6px;
    text-align: justify;
    /* font-family: 'RegularFonts', sans-serif; */
    font-family: 'Exo 2', sans-serif;
    margin: auto;
}

.ap7 {
    font-weight: 600;
    border-radius: 4px;
    border-style: none;
    margin: 0px;
    text-align: center;
    /* margin: auto; */
    background-color: #756D6A;
}

@media (max-width: 767px) {

    .Amain {
        width: 100vw;
        margin-top: 0px;
        height: auto;
        padding: 50px 0px;
    }

    .overlayxy {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100vw;
        height: 320px;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent black background */
        display: flex;
        align-items: center;
        justify-content: center;
    }



    .Amain2 {
        width: 100vw;
        height: 150px;
        margin: auto;
        padding: 0px;
        display: table-cell;
        vertical-align: middle;
    }

    .ap {

        font-size: 37px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0.065em;
        text-align: center;
    }

    .ap2 {
        color: #D7BD92;
        font-size: 28px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0.05em;
        text-align: center;
    }

    .Amain3 {
        display: table-cell;
        vertical-align: middle;
        width: 100vw;
        height: auto;
        margin: auto;
        padding: 0px 0px;

    }

    .ap3 {

        /* font-family: 'RegularFonts', sans-serif; */
        font-size: 17px;
        font-weight: 600;
        line-height: 35px;
        width: 80vw;
        margin: auto;

    }

    .ap4 {
        /* font-family: 'BlueLB'; */

        font-size: 35px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;


    }

    .ap6 {
        width: auto;
        height: 48px;
        font-weight: 600;
        border-radius: 4px;
        color: aliceblue;
        border-style: none;
        margin: 0px;
        text-align: center;
        background-color: #756D6A;
        margin-top: 25px;

    }

    .Amain5 {
        width: 85vw;
        height: auto;
        background-color: #909090;
        box-shadow: 4px 4px rgb(62, 36, 36);
        padding: 30px;

    }

    .Amain4 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        height: 1500px;
        width: 100vw;
        margin: auto;
        gap: 40px;
    }

    .ap5 {
        font-size: 17px;
        font-weight: 0;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: justify;
        padding-top: 25px;
    }

    .ap8 {
        font-size: 17px;
        font-weight: 0;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: justify;
        margin-top:25px;
    }

    .ap7 {
        width: auto;
        height: 48px;
        font-weight: 600;
        border-radius: 4px;
        color: aliceblue;
        border-style: none;
        text-align: center;
        margin: auto;
        background-color: #756D6A;
        margin-top: 25px;
    }



}

.ap21 {

    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #E6D3B3;
    margin: auto;
    align-items: center;
    text-align: center;
    padding: 100px 0px;
    height: auto;
    gap: 20px;
}

.ap24 {
    /* font-family: 'BlueLB'; */
    font-family: 'Exo 2', sans-serif;
    color: #E6D3B3;
    font-size: 45px;
    text-align: center;
}

.ap25 {
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    padding: 14px;
    letter-spacing: 1.4px;
    text-align: center;


}

.yu {
    font-size: 70px;
    margin-Left: 20px;
}

.ap22 button {
    width: 323px;
    height: 55.371826171875px;
    border-radius: 61px;
    background-color: #e1dcd4;
    color: #1B1B1B;
    border-style: none;
    margin-top: 20px;
    font-weight: 600;
    /* font-family: 'RegularFonts', sans-serif; */
    font-family: 'Exo 2', sans-serif;


}

.ap22 img {
    width: 20px;
    height: 18px;
    border: 2px;
    margin-right: 15px;

}

.ap22 {
    width: 50vw;
    height: 661px;
    background-color: #756D6A;
    color: #E6D3B3;
    padding: 90px 90px;
    max-width: 700px;

}

.ap23 img {
    object-fit: fill;
    height: 100%;
    width: 100%;
    background-color: #6A6665;
}

.ap23 {
    width: 50vw;
    /* max-width: 600px; */
    height: 661px;
    overflow: hidden;
    padding: 70px;
    background-color: #756D6A;

}

@media (max-width: 768px) {
    .ap21 {
        height: auto;
        width: 95vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 0px;
        margin: auto;
        margin-top: 0px;
        text-align: center;
        margin-bottom: 60px;
        box-shadow: 6px 6px rgb(147, 126, 126);
    }

    .yu {
        font-size: 50px;
        font-weight: 600;
        margin-Left: 20px;
    }


    .ap24 {
        margin: 0px;
        padding: 0px;

    }

    .ap25 {
        text-align: justify;
        align-items: center;
        display: flex;
        font-size: 18px;
        padding: 20px;
        letter-spacing: 0px;
        justify-content: center;
    }

    .ap24 {
        font-size: 30px;
        margin: 13px;
        text-align: center;

    }

    .ap22 {
        width: 85vw;
        height: 50%;
        color: #E6D3B3;
        margin: 0px;
        padding: 3px;
        margin-top: 20px;

    }

    .ap22 button {
        width: auto;
        height: auto;
        font-size: 14px;
        margin-bottom: 20px;
        padding: 13px 17px;
    }

    .ap22 img {
        height: 14px;
        width: 14px;
        margin: 0px;
        margin-right: 6px;
        text-align: center;
    }

    /* .ap26 {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 90vw;
        margin: auto;
    } */

    .ap23 img {
        color: #E6D3B3;
        padding: 0px;
        /* padding: 20px; */
        margin-top: 0px;
        height: 100%;
        width: 100%;

    }

    .ap23 {
        width: 85vw;
        height: 280px;
        /* height: 661px; */
        object-fit: fill;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin-bottom: 30px;
    }

    .jk {
        /* width: 400px; */
        text-align: center;
        margin: auto;
        font-size: 35px;

    }

    .jk1 {

        /* width: 400px; */
        text-align: center;
        margin: auto;
        font-size: 25px;
    }

    .jk2 {
        width: 75%;
        text-align: center;
        margin: auto;

    }

}

.jk {
    font-family: 'Exo 2', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 92px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}

.oldimage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 950px;

}

.image-containerrr {
    /* margin: 80px 0px; */
    position: relative;
    text-align: center;
    width: 100vw;
}

.mn {
    width: 90vw;
    height: 729px;
    position: relative;
    background-color: #726D69;
    border-radius: 10px;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: 'Exo 2', sans-serif;
    transform: translate(-50%, -50%);
    text-align: center;
    /* padding: 20px; */
    border-radius: 10px;
    margin: auto;
    width: 100vw;
    height: 600px;

}

.ap36 {
    width: 220.904296875px;
    height: 50.371826171875px;
    padding: 0px;
    background-color: #E6D3B3;
    border-radius: 61px;
    margin-top: 30px;
    color: #1B1B1B;
    font-size: 20px;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;

}

.ap36 img {
    width: 20px;
    height: 18px;
    top: 2509.265625px;
    left: 295.109375px;
    border: 2px;
    margin-right: 15px;
}

.jk1 {
    font-family: 'Exo 2', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}

.jk2 {
    font-family: 'Exo 2', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}

.nm {
    width: 230px;
    height: 230px;
    border-radius: 20px;

}

.nm img {
    height: 230px;
    width: 230px;
    left: 0;
    top: 0;
    object-fit: cover;
    position: fixed;
}

button {
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

@media (width < 328px) {

    .content-container {
        text-align: center;
        position: absolute;
        left: 0px;
        margin: auto;
        width: 100vw;
        color: aliceblue;
    }

    .write1 {
        font-family: 'BlueLB';
        font-size: 35px;
        font-weight: 700;
    }

    .Amain {
        width: 100vw;
        margin-top: 0px;
        height: 100%;
    }

    .Amain2 {
        width: 100vw;
        height: 232px;
        margin: auto;
        padding: 0px;
        display: table-cell;
        vertical-align: middle;
    }

    .ap {
        font-size: 34px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0.065em;
        text-align: center;
    }

    .ap2 {
        color: #D7BD92;
        font-size: 23px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0.05em;
        text-align: center;
    }

    .Amain3 {
        display: table-cell;
        vertical-align: middle;
        width: 100vw;
        height: 150px;
        text-align: center;
        margin: auto;
        /* padding-top: 20px; */


    }

    .ap3 {

        font-size: 23px;
        font-weight: 400;
        line-height: 30px;
        width: 80vw;
        margin: auto;
        text-align: center;

    }

    .ap4 {
        font-size: 32px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;


    }

    .ap6 {
        width: auto;
        height: auto;
        font-weight: 600;
        border-radius: 4px;
        color: aliceblue;
        border-style: none;
        margin: 0px;
        text-align: center;
        background-color: #756D6A;
        margin-top: 20px;
        padding: 10px 18px;
        font-size: 15px;

    }

    .Amain5 {
        width: 95vw;
        height: auto;
        background-color: #909090;
        box-shadow: 4px 4px rgb(62, 36, 36);
        padding: 30px;

    }

    .Amain4 {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        color: white;
        height: 1700px;
        width: 100vw;
        margin: auto;
    }

    .ap5 {
        font-size: 16px;
        font-weight: 0;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: justify;
        padding-top: 20px;
    }

    .ap8 {
        font-size: 17px;
        font-weight: 0;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: justify;
        margin-top: 50px;
    }

    .ap7 {
        width: auto;
        height: 48px;
        font-weight: 600;
        border-radius: 4px;
        color: aliceblue;
        border-style: none;
        text-align: center;
        margin: auto;
        background-color: #756D6A;
        margin-top: 15px;
        padding: 0px 5px;
        font-size: 10px;
    }

    .ap21 {
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 0px;
        margin: auto;
        margin-top: 0px;
        text-align: center;
        margin-bottom: 60px;
        box-shadow: 0px 0px rgb(147, 126, 126);

    }


    .ap24 {
        margin: 0px;
        padding: 0px;

    }

    .ap25 {
        text-align: justify;
        align-items: center;
        display: flex;
        font-size: 17px;
        padding: 0px 10px;
        justify-content: center;
    }

    .ap24 {
        font-size: 30px;
        margin: 13px;
        text-align: center;

    }

    .ap22 {
        width: 85vw;
        height: 50%;
        color: #E6D3B3;
        margin: 0px;
        padding: 0px;
        /* padding: 50px; */
        margin-top: 20px;

    }

    .ap22 button {
        width: auto;
        height: 40px;
        font-size: 13px;
        margin-bottom: 20px;
        font-weight: 600;

    }

    .ap22 img {
        height: 12px;
        width: 12px;
        margin: 0px;
        margin-right: 6px;
        text-align: center;
    }

    /* .ap26 {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 90vw;
        margin: auto;
    } */

    .ap23 img {
        color: #E6D3B3;
        padding: 0px;
        /* padding: 20px; */
        margin-top: 0px;
        height: 100%;
        width: 100%;

    }

    .ap23 {
        width: 85vw;
        height: 250px;
        /* height: 661px; */
        object-fit: fill;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin-bottom: 30px;
    }

    .jk {
        /* width: 400px; */
        text-align: center;
        margin: auto;
        font-size: 30px;

    }

    .jk1 {

        /* width: 400px; */
        text-align: center;
        margin: auto;
        font-size: 20px;
    }

    .jk2 {
        width: 75%;
        text-align: center;
        margin: auto;
        font-size: 30px;
    }

    .ap36 {
        width: auto;
        padding: 0px 20px;
        font-size: 16px;
    }

    .ap36 img {
        width: 16px;
        height: 16px;
        border: 2px;
        margin-right: 5px;
    }
}



@media (min-width: 768px) and (max-width: 1024px) {
    .overlayxy {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100vw;
        height: 320px;
        background-color: rgba(0, 0, 0, 0);
        /* Semi-transparent black background */
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .Amain {
        width: 100vw;
        margin-top: 0px;
        height: auto;
    }

    .Amain2 {
        width: 100vw;
        height: 232px;
        margin: auto;
        padding: 0px;
        display: table-cell;
        vertical-align: middle;
    }

    .ap {
        font-size: 37px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0.065em;
        text-align: center;

    }

    .ap2 {
        color: #D7BD92;
        font-size: 28px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0.05em;
        text-align: center;
    }

    .Amain3 {
        display: table-cell;
        vertical-align: middle;
        width: 100vw;
        height: 150px;
        text-align: center;
        margin: auto;
        /* padding-top: 20px; */


    }

    .ap3 {

        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        width: 70vw;
        margin: auto;
        text-align: center;
        font-weight: 600;

    }

    .ap4 {
        font-size: 35px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;


    }

    .ap6 {
        width: 149.64px;
        height: 48px;
        font-weight: 600;
        border-radius: 4px;
        color: aliceblue;
        border-style: none;
        margin: 0px;
        text-align: center;
        background-color: #756D6A;
        margin-top: 25px;

    }

    .Amain5 {
        width: 50vw;
        height: auto;
        background-color: #909090;
        box-shadow: 4px 4px rgb(62, 36, 36);
        padding: 0px;

    }

    .Amain4 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        height: auto;
        width: 100vw;
        margin: auto;
        gap: 0px;
    }

    .ap5 {
        font-size: 17px;
        font-weight: 0;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: justify;
        padding-top: 25px;
    }

    .ap8 {
        font-size: 17px;
        font-weight: 0;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: justify;
        margin-top: 50px;
    }

    .ap7 {
        width: 180px;
        height: 48px;
        font-weight: 600;
        border-radius: 4px;
        color: aliceblue;
        border-style: none;
        text-align: center;
        margin: auto;
        background-color: #756D6A;
        margin-top: 25px;
    }


    .ap21 {
        height: auto;
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 0px;
        flex-wrap: wrap;
        margin: auto;
        margin-top: 0px;
        text-align: center;
        margin-bottom: 60px;
        box-shadow: 6px 6px rgb(147, 126, 126);
    }

    .yu {
        font-size: 50px;
        font-weight: 600;
        margin-Left: 20px;
    }


    .ap24 {
        margin: 0px;
        padding: 0px;

    }

    .ap25 {
        text-align: justify;
        align-items: center;
        display: flex;
        font-size: 18px;
        padding: 20px;
        justify-content: center;
    }

    .ap24 {
        font-size: 30px;
        margin: 13px;
        text-align: center;

    }

    .ap22 {
        width: 85vw;
        height: 50%;
        color: #E6D3B3;
        margin: 0px;
        padding: 0px;
        /* padding: 50px; */
        margin-top: 20px;

    }

    .ap22 button {
        width: 50%;
        height: 40px;
        font-size: 14px;
        margin-bottom: 20px;

    }

    .ap22 img {
        height: 14px;
        width: 14px;
        margin: 0px;
        margin-right: 6px;
        text-align: center;
    }

    /* .ap26 {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 90vw;
        margin: auto;
    } */

    .ap23 img {
        color: #E6D3B3;
        padding: 0px;
        /* padding: 20px; */
        margin-top: 20px;
        object-fit: cover;
        height: 100%;
        width: 100%;

    }

    .ap23 {
        width: 85vw;
        height: 50%;
        /* height: 661px; */
        object-fit: contain;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin-bottom: 30px;
        background-color: unset;

    }

    .jk {
        /* width: 400px; */
        text-align: center;
        margin: auto;
        font-size: 35px;

    }

    .jk1 {

        /* width: 400px; */
        text-align: center;
        margin: auto;
        font-size: 25px;
    }

    .jk2 {
        width: 75%;
        text-align: center;
        margin: auto;

    }


    .Amain {
        width: 100vw;
        margin-top: 0px;
        height: 1800px;
    }

    .Amain2 {
        width: 100vw;
        height: 160px;
        margin: auto;
        padding: 0px;
        display: table-cell;
        vertical-align: middle;
    }

    .overlayxy {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100vw;
        height: 320px;
        background-color: rgba(0, 0, 0, 0.4);
        /* Semi-transparent black background */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ap {

        font-size: 37px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0.065em;
        text-align: center;
    }

    .ap2 {
        color: #D7BD92;
        font-size: 28px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0.05em;
        text-align: center;
    }

    .Amain3 {
        display: table-cell;
        vertical-align: middle;
        width: 100vw;
        height: 150px;
        text-align: center;
        margin: auto;
        /* padding-top: 20px; */


    }

    .ap3 {

        font-size: 23px;
        font-weight: 600;
        line-height: 30px;
        width: 70vw;
        margin: auto;
        text-align: center;

    }

    .ap4 {
        font-size: 35px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;


    }

    .ap6 {
        width: 149.64px;
        height: 48px;
        font-weight: 600;
        border-radius: 4px;
        color: aliceblue;
        border-style: none;
        margin: 0px;
        text-align: center;
        background-color: #756D6A;
        margin-top: 25px;

    }

    .Amain5 {
        height: auto;
        background-color: #909090;
        box-shadow: 4px 4px rgb(62, 36, 36);
        padding: 40px;

    }

    .Amain4 {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        color: white;
        height: 1300px;
        width: 100vw;
        margin: auto;
    }

    .ap5 {
        font-size: 17px;
        font-weight: 0;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: justify;
        padding-top: 25px;
    }

    .ap8 {
        font-size: 17px;
        font-weight: 0;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: justify;
        margin-top: 50px;
    }

    .ap7 {
        width: 180px;
        height: 48px;
        font-weight: 600;
        border-radius: 4px;
        color: aliceblue;
        border-style: none;
        text-align: center;
        margin: auto;
        background-color: #756D6A;
        margin-top: 25px;
    }




}
/* Hero.css */
.Hero1 {
    position: relative;
    height: 709px;
    width: 100vw;
    overflow: hidden;

}

.Hero1 .hero-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 40px;
    left: 0;
}

.sticky-navbar {
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 10; /* Make sure the z-index is higher than that of the image */
}

@media (max-width: 768px) {

    .Hero1 {
        position: relative;
        height: 400px;
        width: 100vw;
        overflow: hidden;

    }
    
    .Hero1 .hero-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 40;
        left: 0;
    }
    
    .sticky-navbar {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 10; /* Make sure the z-index is higher than that of the image */
    }



}
@media (min-width: 768px) and (max-width: 1024px) {


    .Hero1 {
        position: relative;
        height: 400px;
        width: 100vw;
        overflow: hidden;

    }
    
    .Hero1 .hero-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 40;
        left: 0;
    }
    
    .sticky-navbar {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 10; /* Make sure the z-index is higher than that of the image */
    }







}
.navbar {
  height: 80px;
  width: 100vw;
  top: 0;
  background-color: #656564;
}

.navbar5 {
  display: none;
}
.dropnav {
  font-size: 18px;
}
.dropdown-menu {
  background-color: "#656564";
}

.dropdown-item {
  color: black !important;
  font-weight: 600;
  font-size: 18px;
}

.dropdown-item:hover {
  color: black !important;
  background-color: "#656564";
}

.navbar2 {
  height: 60px;
  width: 192px;
}

.navbar2 img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.navbar4 {
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 100vw;
  padding: 0px 25px;
}

.navbar3 li {
  font-size: 18px;
  color: white;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
}

.navbar3 {
  gap: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
}

.navbar3 button {
  border-radius: 0px;
  font-weight: 600;
  color: #756d6a;
}

.navbar3 li a:hover,
.navbar3 li a:active {
  color: black;
  text-decoration: none;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
}

/* Fullscreen overlay to center the popup */
.overlay {
  position: absolute;
  top: -200px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Dimmed background */
  display: flex;
  align-items: center; /* Ensures vertical centering */
  justify-content: center; /* Ensures horizontal centering */
  z-index: 9999;
  overflow-y: auto; /* Allows scrolling if content overflows */
  padding: 20px; /* Prevents content touching screen edges */
}

/* Popup container */
.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 350px;
  max-width: 90%; /* Responsive width */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.submit-button {
  background-color: #28a745;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #218838;
}

.close-button {
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
  background-color: transparent;
  top: 0 !important;
}

.close-button:hover {
  background-color: #c82333;
}

@media (max-width: 767px) {
  .navbar5 {
    top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 60px;
    background-color: #656564;
    padding: 0px 10px;
  }

  .navbar6 {
    height: 45px;
    width: 120px;
  }

  .navbar6 img {
    object-fit: fill;
    height: 100%;
    width: 100%;
  }

  .navbar7 {
    padding: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0px;
  }

  .navbar3 li a:hover,
  .navbar3 li a:active {
    color: black;
    text-decoration: none;
  }

  .navbar7 button {
    background-color: aliceblue;
    color: #756d6a;
    border-radius: 0px;
  }

  .hm {
    right: 0%;
    z-index: 2;
    position: relative;
    height: 40px;
    transition: all 0.3s ease;
  }

  .gaft {
    font-size: 12px;
    height: 40px;
    width: auto;
    color: #756d6a;
    font-weight: 600;
    margin-right: 10px;
  }

  .navbar4 {
    height: 0px;
  }

  .navbar3,
  .navbar2,
  .navbar9 button {
    display: none;
  }

  .navbar9 {
    flex-direction: column;
    width: 100%;
    height: auto;
    top: 0%;
    background-color: #656564;
    z-index: 2;
    position: absolute;
    list-style: none;
    padding: 30px 0px;
    margin: auto;
    justify-content: center;
    text-align: center;
    line-height: 50px;
    left: 0%;
  }
  .navbar9 li a:hover,
  .navbar9 li a:active {
    color: black;
    text-decoration: none;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar3 li a:hover,
  .navbar3 li a:active {
    color: black;
    text-decoration: none;
  }

  .navbar5 {
    top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 60px;
    background-color: #656564;
    padding: 0px 20px;
  }

  .navbar6 {
    height: 45px;
    width: 120px;
  }

  .navbar6 img {
    object-fit: fill;
    height: 100%;
    width: 100%;
  }

  .navbar7 button {
    background-color: aliceblue;
    margin-right: 20px;
    color: #756d6a;
    border-radius: 0px;
  }

  .hm {
    right: 0%;
    z-index: 2;
    position: relative;
  }

  .gaft {
    width: auto;
    color: #756d6a;
    font-weight: 600;
  }

  .navbar4 {
    height: 0px;
  }

  .navbar3,
  .navbar9 button {
    display: none;
  }

  .navbar9 {
    flex-direction: column;
    width: 100%;
    height: auto;
    top: 0%;
    background-color: #656564;
    z-index: 2;
    position: absolute;
    list-style: none;
    padding: 0px 0px;
    margin: auto;
    justify-content: center;
    text-align: center;
    line-height: 55px;
    left: 0%;
  }
  .navbar9 li a:hover,
  .navbar9 li a:active {
    color: black;
    text-decoration: none;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
  }
}

@media (width < 328px) {
  .navbar5 {
    top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 60px;
    background-color: #656564;
    padding: 0px 5px;
  }

  .navbar6 {
    height: 35px;
    width: 110px;
  }

  .navbar6 img {
    object-fit: fill;
    height: 100%;
    width: 100%;
  }

  .navbar7 button {
    background-color: aliceblue;
    margin-right: 5px;
    color: #756d6a;
    border-radius: 0px;
    text-align: center;
    height: 35px;
  }

  .hm {
    right: 0%;
    z-index: 2;
    position: relative;
    text-align: center;
  }

  .gaft {
    width: 90px;
    padding: 0px;
    font-size: 10px;
    color: #756d6a;
    font-weight: 600;
  }

  .navbar4 {
    height: 0px;
  }

  .navbar3,
  .navbar9 button {
    display: none;
  }

  .navbar9 {
    flex-direction: column;
    width: 100%;
    height: 500px;
    top: 0%;
    background-color: #656564;
    z-index: 2;
    position: absolute;
    list-style: none;
    padding: 0px;
    margin: auto;
    justify-content: center;
    text-align: center;
    line-height: 50px;
    left: 0%;
  }

  .navbar9 li a:hover,
  .navbar9 li a:active {
    color: black;
    text-decoration: none;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
  }
}

.lain5 {
    margin: 0px 0px 50px 0px;
}



.popopo {
    width: 100vw;
}

.image-containerq {
    position: relative;
    text-align: center;
    width: 100vw;
    height: 351px;

}

.image-containerq img {
    height: auto;
    height: 351px;
    display: block;
    object-fit: cover;
    background-color: rgb(45, 40, 45);
}

.mainoo {

    font-family: 'Exo 2', sans-serif;
    font-weight: 600;    font-size: 16px;
    color: #ffff;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-align: center;
    width: 750px;
    margin: auto;
}

.text-boxq {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* padding: 20px; */
    /* border-radius: 10px; */
    text-align: center;
    width: 90vw;
    height: 230px;
}

.main5o {

    font-family: 'Exo 2', sans-serif;
    font-weight: 600;    font-size: 18px;
    /* font-weight: 700; */
    letter-spacing: 0.1em;
    text-align: center;
    color: #ffff;
    /* margin-bottom: 15px; */
}

.button-containerq {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.button-containerq button {
    background-color: #D7BD92;
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;

    font-family: 'Exo 2', sans-serif;
    font-weight: 600;    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    margin: 0px 10px;
    text-align: center;
    width: 180.904296875px;
    height: 50.97007751464844px;
    border-radius: 0px;

}

.main5ooo {
    font-family: 'BlueL';
    color: #D7BD92;
    font-size: 28px;
    margin: 13px 0px;
}


@media screen and (min-width: 320px) and (max-width: 1023px) {

    .lain5 img {
        height: 340px;
        width: 100%;
        opacity: calc(320%);
    }

    .popopo {
        object-fit: fill;
    }

    .main5o {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        line-height: 26px;

    }

    .mainoo {
        font-size: 15px;
        width: 100vw;
        padding: 0px;
        line-height: 27px;
        text-align: center;

    }

    .main5ooo {
        font-size: 28px;
        margin: 12px;


    }

    .button-containerq button {
        font-size: 14px;
        padding: 0;
        height: 38px;
        width: 150px;
        font-weight: 600;
        margin: 0;
        margin-top: 5px;

    }

    .text-boxq {
        width: 100vw;
        text-align: center;
        margin: 0px;
        top: 35%;
        padding: 0px;

    }

    .lain5 {
        height: 340px;
        width: 100vw;
        line-height: 50px;

    }

    .button-containerq {
        margin: 0;
    }
}
@media screen and (max-width: 1300px) and (min-width: 768px) {
    .text-boxq {
        width: 100vw;
        text-align: center;
        margin: 0px;
        top: 50%;
        padding: 0px;

    }





}
@media (width < 328px) {
    .button-containerq button {
        font-size: 12px;
        padding: 10px 15px;
        height: 38px;
        width: auto;

        font-weight: 0;
        margin: 0;
        margin-right: 10px;
        margin-top: 5px;

    }

    .text-boxq {
        width: 100vw;
        text-align: center;
        margin: 0px;
        top: 35%;

    }


}
.m41 {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #756d6a;
}

.m4 {
  border: 2px;
  width: 50%;
  /* margin-top: 20px; */
  padding: 100px;
}

.m42 {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  /* font-family: 'Demibolds', sans-serif; */
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 2px;
  text-align: left;
}

.m43 {
  /* font-family: 'RegularFonts', sans-serif; */

  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: justify;
  margin-top: 40px;
}

.ip,
.mob {
  display: none;
}

.m0040 {
  width: 782px;
  height: 600px;
  overflow: hidden;
}

.m0040 img {
  object-fit: fill;
  height: 130%;
  width: 100%;
  padding: 40px;
}

.m44 {
  width: 230px;
  height: 47.371826171875px;
  border-radius: 61px;
  background-color: #e2d0b3;
  color: black;
  border-style: none;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-top: 40px;
}

.m04 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 650px;
  /* padding-top: 50px; */
  /* background-color: #ddb581; */
}

.pricing-action {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

@media (max-width: 768px) {
  .m04 {
    width: 90vw;
    margin: auto;
    height: auto;
    margin-top: 50px;
    flex-direction: column;
  }

  .m41 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  .m42 {
    font-size: 30px;
    text-align: center;
  }

  .m43 {
    /* font-family: 'Demibolds', sans-serif; */

    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 17px;
    text-align: justify;
    margin-top: 30px;
    width: 100%;
  }

  /* .m4 {
        height: auto;
        width: 100%;
        margin-top: 0px;

    } */
  .m4 {
    height: auto;
    width: 100%;
    padding: 0px;
    padding: 10px;
    text-align: center;
  }

  .m0040 {
    height: 300px;
    object-fit: cover;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    margin-top: 0px;
    overflow: hidden;
  }

  .m0040 img {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .m44 {
    width: auto;
    height: 47px;
    border-radius: 61px;
    background-color: #dfd6c7;
    color: black;
    font-weight: 600;
    border-style: none;

    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 13px;
    padding: 0px 40px;
    margin-top: 10px;
  }
}

.op {
  position: relative;
  /* margin-top: 90px; */
  height: 300px;
  width: 100vw;
}

.op img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* Crop 25% from top and bottom */
  object-position: 30% 45%;
  filter: contrast(80%);
  filter: brightness(50%);
}

.text-box3 {
  position: absolute;
  color: white;
  margin: auto;
  text-align: center;
  height: 200px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
}

.op1 {
  font-family: "BlueL";
  letter-spacing: 0.9px;
  font-size: 40px;
  color: #d7bd92;
  word-spacing: 4px;
}

.op3 {
  background-color: rgb(223, 214, 214);
  color: #656564;
  border: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 1200;
  height: 50px;
  border-radius: 0px;
  letter-spacing: 1.4px;
  font-family: "Exo 2", sans-serif;
  margin-top: 10px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  /* .text-box3 {
        height: auto;
        margin-left: 250px;
        width: 100%;
        text-align: center;
        background-color: black;
        margin: auto;
        margin-top: -150px; 
    } */

  .po1 {
    width: 85vw;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }

  .abhi {
    width: 85vw;
  }

  .text-box3 {
    color: rgba(255, 236, 189, 0.736);
    border-radius: 10px;
    text-align: center;
    width: 100vw;
    height: auto;
    height: 300px;
  }

  .op1 {
    font-size: 27px;
    letter-spacing: 2px;
    font-weight: 600;
  }

  .op {
    margin-top: 60px;
  }

  .op button {
    height: 50px;
    color: black;
  }

  .op3 {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
}

.image-text-container0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 270px;
  height: 330px;
  padding: 40.5px 5px 40.5px 5px;
  transition: 0.4s;
  background-color: #f8f6f3;
}

.image-text-container0 img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 20px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  /* .text-boxx p {
        font-size: 12px;
    } */
  .pp1 {
    font-size: 25px;
  }
}

.image-text-container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  height: 330px;
  padding: 40.5px 0px 40.5px 0px;
  background-color: #f8f6f3;
  align-items: center;
  transition: 0.4s;
  text-align: center;
}

.image-text-container1 img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 20px;
}

.pp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 450px;
  gap: 40px;
}

.text-boxy button,
.text-boxx button {
  background-color: #ffffff;
  color: rgb(123, 106, 81);
  border: none;
  font-weight: 600;
  text-align: center;
  height: 42px;
  width: 140px;
  cursor: pointer;
  font-size: 15px;
  border: black 1px solid;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .text-boxy .pp1 {
    font-size: 18px;
  }

  .text-boxx .pp1 {
    font-size: 18px;
  }

  .po {
    text-align: center;
    margin: auto;
    height: 1239px;
    width: 100%;
  }

  .pp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 840px;
  }

  .image-text-container0 {
    margin: 45px;
  }

  .image-text-container1 {
    margin: 25px;
  }
}

.Plan {
  height: 550px;
  width: 100%;
}

.pp1 {
  font-family: "BlueL";
  font-weight: 600;
  font-size: 16px;
  /* margin-bottom: 20px; */
  letter-spacing: 1.4px;
}

.pp2 {
  /* font-family: 'RegularFonts', sans-serif; */
  font-size: 14px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 20px;
  width: 185px;
}

.pp3 {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 23px; */
  letter-spacing: 1px;
  text-align: center;
  color: #ddb581;
}

/* .pp4 {
    margin-top: 20px;
} */

.po {
  border: 2px;
  height: 700px;
  padding: 40px;
}

.po1 {
  font-family: "RegularFonts", sans-serif;
  /* font-family: 'Exo 2', sans-serif; */
  font-weight: 600;
  font-size: 35px;
  line-height: 66px;
  letter-spacing: 1.6px;
  text-align: center;
}

.po2 {
  width: 670px;
  height: 69px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 17px;
}

@media (max-width: 768px) {
  .po {
    text-align: center;
    height: auto;
    margin-top: 30px;
  }

  .Plan {
    height: 100%;
    width: 90vw;
  }

  .po2 {
    width: 100%;
    text-align: center;
    margin: auto;
  }

  .abhi {
    height: 200px;
  }

  .image-text-container0:hover {
    box-shadow: 0 0 2px;
    margin-top: -20px;
  }

  .image-text-container1:hover {
    box-shadow: 0 0 2px;
    margin-top: -20px;
  }
}

/* ToggleSwitch.css */
.toggle-switch {
  display: flex;
  align-items: center;
}

.checkbox {
  display: none;
}

.label {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #333;
}

.bn {
  margin: 50px 0px;
}

.toggle-label:last-child {
  left: auto;
  right: 10px;
}

.checkbox:checked ~ .label {
  background-color: #66bb6a;
}

.hindi-content,
.english-content {
  margin-top: 20px;
}

.image-text-container0:hover {
  box-shadow: 0 0 2px;
  margin-top: -20px;
}

.image-text-container1:hover {
  box-shadow: 0 0 2px;
  margin-top: -20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Plan {
    height: 100%;
    width: 90vw;
    margin: auto;
  }

  .Plan img {
    width: 100%;
    height: 100%;
  }

  .model {
    width: 95vw;
    height: 100%;
    margin: auto;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: auto;
    text-align: center;
  }

  .modal-content {
    margin: auto;
    width: 95vw;
  }

  .m04 {
    width: 90vw;
    margin: auto;
    height: auto;
    margin-top: 50px;
    flex-direction: column;
    padding-bottom: 50px;
  }

  .m41 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  .m42 {
    font-size: 30px;
    text-align: center;
  }

  .m43 {
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: justify;
    margin-top: 30px;
    width: 100%;
    line-height: 30px;
  }

  /* .m4 {
        height: auto;
        width: 100%;
        margin-top: 0px;

    } */
  .m4 {
    height: auto;
    width: 100%;
    padding: 0px;
    padding: 10px;
    text-align: center;
  }

  .m0040 {
    height: auto;
    object-fit: cover;
    width: auto;
    padding: 0px;
    margin: 0px;
    margin-top: 0px;
  }

  .m0040 img {
    width: 100%;
    height: 100%;
  }

  .m44 {
    width: 200px;
    height: 50.371826171875px;
    border-radius: 61px;
    background-color: #dfd6c7;
    color: black;
    font-weight: 600;
    border-style: none;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 13px;
    padding: 0;
    margin-top: 40px;
  }
}

@media (width < 328px) {
  .text-box3 {
    color: rgba(255, 236, 189, 0.736);
    border-radius: 10px;
    text-align: center;
    width: 100vw;
    height: auto;
    height: 250px;
  }

  .op1 {
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 600;
  }

  .op {
    margin-top: 60px;
    height: 250px;
  }

  .op button {
    height: 40px;
    width: 140px;
  }

  .op3 {
    font-size: 12px;
    color: white;
  }
}

.faqss{
    text-align: center;
    margin: auto;
    position: fixed;
    height: 60px;
    width: 70px;
    z-index: 100;
    left:15px;
    background-color: aliceblue;
    padding: 0px;
    top:90%;
}

.faqss img{
    /* font-size: 15px; */
    text-align: center; /* Adjust the font size to make the icon bigger */
    /* color: white;  */
    height:60px;
    width: 60px;
    
}
@media (max-width: 768px) {
    .faqss img{
        height:45px;
        width:45px;
    }
.faqss{
    height: 50px;
    width: 50px;
}


}
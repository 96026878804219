.cun {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: #E6D3B3;
    width: 100vw;
    margin: auto;
    gap: 10px;
}

.cun1 {
    width: 100vw;
    height: auto;
    padding: 50px 0px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

}

.cun1 h4 {
    font-weight: 600;
    font-size: 34px;
    padding: 0px 0px;
    margin: 0px;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;

}

.cun1 h6 {
    font-size: 26px;
    font-weight: 0px;
    padding: 0px 0px;
    margin: 0px;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}

.cun2 {
    width: 100vw;
    height: auto;
    text-align: center;
    padding: 50px 0px;
}

.cu1 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 40px;
    padding: 0px 10px;
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}

.cu2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 14px;
    
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}

.cu2 img {
    filter: contrast(0%);
}

.cu8 {
    border: 3px solid black;
    height: 180px;
}

.cu6,
.cu7 {
    display: flex;
    justify-content: center;
    gap: 10px;

}

.cu5,
.cu6,
.cu7 {
    margin: 0px;
    font-size: 17px;
}

.cu5 {
    font-weight: 600;
    font-size: 21px;
}


.tycc13 {
    height: auto;
    display: flex;
    justify-content: center;
    /* width: 100vw; */
    align-items: center;

}

.tycc14 {
    height: 185px;
    padding-top: 40px;
    /* width: 100vw; */
}

.tycc14 input {
    margin: 10px;
    height: 40px;
    font-size: 12px;
    padding: 10px;
    width: 18vw;
    border: 1px solid rgb(190, 186, 186);

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}


.tycc13 textarea {
    width: 37vw;
    font-size: 12px;
    padding: 10px;
    border: 1px solid rgb(190, 186, 186);

}

.tycc13 button {
    border-radius: 0px;
    font-weight: 600;
    background-color: #6A6665;
}


.tycc12 {

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}

.tycc12 h4 {
    font-size: 28px;
    font-weight: 600;

    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
}

.cu9 {
    border: 2px black solid;
    width: 130px;
    margin: auto;
    margin-top: 15px;
}

@media (max-width: 767px) {

    .tycc12 {
        height: 470px;
        width: 100vw;
        text-align: center;
        padding-top: 30px;

    }

    .tycc13 {
        height: auto;
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .tycc14 {
        height: 150px;
        padding-top: 40px;
        width: 100vw;
        margin: auto;
    }

    .tycc14 input {
        margin: 5px;
        height: 40px;
        font-size: 12px;
        padding: 10px;
        width: 46vw;
        border: 1px solid rgb(190, 186, 186);
    }

    .tycc13 textarea {
        width: 95vw;
        font-size: 12px;
        padding: 10px;
        border: 1px solid rgb(190, 186, 186);

    }

    .tycc13 button {
        border-radius: 0px;
        font-weight: 600;
        background-color: #6A6665;


    }

    .cun {
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        height: auto;
        background-color: #E6D3B3;
        width: 100vw;
    }

    .cun1 {
        width: 100vw;
        height: auto;
        margin: auto;
        padding: 50px 50px;

    }

    .cun1 h4 {
        font-size: 34px;
        padding: 0px 0px;
    }

    .cun1 h6 {
        font-size: 28px;
        font-weight: 0px;
        padding: 0px 0px;

    }

    .cun2 {
        width: 100vw;
        height: 600px;
        text-align: center;
        padding-top: 50px;
    }


    .writte1 {
        font-size: 45px;
        letter-spacing: 2px;
    }

    .cu1 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        padding: 0px 0px;
        width: 100vw;
    }

    .cu2 {
        display: flex;
        padding: 0px 0px;
        flex-direction: column;
        justify-content: center;
        text-align:center;
        gap: 14px;
        height: 288px;
        width: 242px;
    }

    .cu2 img {
        filter: contrast(0%);
    }

    .cu8 {
        border: 3px solid black;
        height: 160px;
    display: none;
    }

    .cu6,
    .cu7 {
        display: flex;
        justify-content: flex-start;
        gap: 10px;

    }

    .cu5,
    .cu6,
    .cu7 {
        margin: 0px;
        font-size: 18px;
    }

    .cu5 {
        font-weight: 600;
        font-size: 21px;

    }




}

@media (min-width: 768px) and (max-width: 1024px) {


    .tycc12 {
        height: 470px;
        width: 100vw;
        text-align: center;
        padding-top: 30px;

    }

    .tycc13 {
        height: auto;
        width: 100vw;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 0px;
    }

    .tycc14 {
        height: 150px;
        padding-top: 40px;
        width: 100vw;
        margin: auto;
    }

    .tycc14 input {
        margin: 5px;
        height: 40px;
        font-size: 12px;
        padding: 10px;
        width: 40vw;
        border: 1px solid rgb(190, 186, 186);
    }

    .tycc13 textarea {

        width: 81vw;
        font-size: 12px;
        padding: 10px;
        border: 1px solid rgb(190, 186, 186);

    }

    .tycc13 button {
        border-radius: 0px;
        font-weight: 600;
        background-color: #6A6665;


    }

    .cun {
        justify-content: center;
        gap: 30px;
        flex-direction: column;
        align-items: center;
        height: 1200px;
        padding: 0px;
        margin: auto;
        background-color: #E6D3B3;
        width: 100vw;
    }

    .cun1 {
        width: 100vw;
        height: 600px;
        align-items: center;
        padding: 0px 0px;
        padding-left: 0px;
    }

    .cun1 h4 {
        font-size: 34px;
        padding: 20px 0px;
    }

    .cun1 h6 {
        font-size: 19px;
        font-weight: 0px;
        padding: 5px 0px;

    }

    .cun2 {
        width: 100vw;
        height: 600px;
        text-align: center;
        padding: 0px 0px;

    }


    .writte1 {
        font-size: 70px;
        letter-spacing: 2px;
    }


    .cu1 {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 20px;
        padding: 0px 10px;

    }

    .cu2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 14px;
    }

    .cu2 img {
        filter: contrast(0%);
    }

    .cu8 {
        border: 3px solid black;
        height: 150px;
    }

    .cu6,
    .cu7 {
        display: flex;
        justify-content: center;
        gap: 10px;

    }

    .cu5,
    .cu6,
    .cu7 {
        margin: 0px;
        font-size: 17px;
    }

    .cu5 {
        font-weight: 600;
        font-size: 21px;
    }






}

@media (width < 328px) {

    .contentt-container {
        top:40%;
        left: 0%;

    }


    .cu1 {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 0px 10px;

    }

    .cu2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: start;
        gap: 14px;
    }

    .cu2 img {
        filter: contrast(0%);
    }

    .cu8 {
        border: 3px solid black;
        height: 150px;
    }

    .cu6,
    .cu7 {
        display: flex;
        justify-content: flex-start;
        gap: 10px;

    }

    .cu5,
    .cu6,
    .cu7 {
        margin: 0px;
        font-size: 17px;
    }

    .cu5 {
        font-weight: 600;
        font-size: 21px;
    }


}
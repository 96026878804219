

.bb {

    font-size: 25px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 20px;

}


.cc {
    font-family: 'RegularFonts',sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin: auto;

}

.vvv {
    width: 35vw;
    height: 50%;

}

.io {

    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 800px;
    width: 100vw;
}

.ioo {
    display: flex;
    flex-direction: column;
    width: 50vw;
    padding: 0px 0px;
    gap: 50px;
    max-width: 500px;

}

.ioo p {
    text-align: justify;
}

.ioo button {
    width: 257.59px;
    height: 49.4px;
    margin: 0px 0px 0px 70px;
    color: #1B1B1B;
    font-weight: 600;
    border-radius: 0;
    background-color: white;
    border: 1px solid black;
}

.qw {
    font-family: 'Comfortaa', cursive;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 130px;
    text-align: center;
    width: 100vw;
    /* padding-top: 90px; */
    height: 100px;
}



.bb {
    /* font-family: 'RegularFonts',sans-serif; */
    font-family: 'Comfortaa', cursive;

    font-size: 33px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 20px;

}

.cc {
    /* font-family: 'RegularFonts',sans-serif; */
    font-family: 'Exo 2', sans-serif;

    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin: auto;

}

.vvv {
    width: 100%;
    height: 100%;
    /* min-width: 80vw; */


}

.io {

    display: flex;
    justify-content: center;
    margin: auto;
    height: 800px;
    width: 100vw;
    gap: 50px;
    /* min-width: 90vw; */
}

.wer {
    width: 50vw;
    text-align: center;
    object-fit: cover;
    padding: 0px;
    height: 800px;
    justify-content: center;
    max-width: 500px;
    /* min-width: 80vw; */
    overflow: hidden;

}
.wer img {
    padding: 60px;
    object-fit: fill;
    height: 100%;
    width: 100%;
}



.ioo p {
    text-align: justify;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .io {
        justify-content: center;
        gap: 50px;
        flex-direction: column;
        margin: auto;
        height:auto;
        width: 100vw;
        padding: 80px 0px;
    }

    .wer {
        width:100vw;
        text-align: center;
        height: 100%;
        justify-content: center;
        /* padding-top: 50px; */
    }

    .wer img {
        /* height: 70%;
        width: 70%;  */
        box-shadow: 8px 8px rgb(87, 85, 85);
        height: 100%;
        width: 100%;
        padding: 0px;
        object-fit: cover;

    }

    .ioo {
        flex-direction: column;
        width: 100vw;
        gap: 50px;
        height: auto;
    }

    .vvv {
        height: auto;
        padding: 13px 0px;
        margin-bottom: 0px;
        justify-content: center;
        flex-direction: column;
    }

}

@media (max-width: 767px) {

    .io {
        justify-content: center;
        gap: 50px;
        flex-direction: column;
        margin: auto;
        height:auto;
        width: 100vw;
        padding: 80px 0px;

    }

    .wer {
        width:90vw;
        text-align: center;
        height: auto;
        justify-content: center;
        overflow: hidden;
        /* padding-top: 50px; */
        object-fit: contain;

    }

    .wer img {
        /* height: 70%;
        width: 70%;  */
        box-shadow: 8px 8px rgb(87, 85, 85);
        height: 100%;
        width: 100%;
        padding: 0px;

    }

    .ioo {
        flex-direction: column;
        width:90vw;
        gap: 30px;
        height: auto;
    }

    .vvv {
        height: auto;
        padding: 10px 0px;
        margin-bottom: 0px;
        justify-content: center;
        flex-direction: column;
    }



}


@media (width < 328px) {

    .io {

        justify-content: space-around;
        flex-direction: column;
        margin: auto;
        height: auto;
        width: 100vw;
    }

    .wer {
        width: 100vw;
        text-align: center;
        /* object-fit: cover; */
        padding: 15px;
        height: auto;
        justify-content: center;
        /* padding-top: 50px; */
    }

    .wer img {
        /* height: 70%;
        width: 70%;  */
        box-shadow: 8px 8px rgb(87, 85, 85);

    }

    .ioo {
        display: flex;
        flex-direction: column;
        width: 100vw;
        padding:40px 0px;
        height: auto;
    }

    .vvv {
        width: 100vw;
        height: 50%;
        padding: 13px 40px;
        margin-bottom: 30px;
        justify-content: space-between;
        flex-direction: column;
    }

    .ioo button {
        width: 257.59px;
        height: 49.4px;
        margin: 30px 0px 0px 50px;
    }

}
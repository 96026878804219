.accordio {
    border: 0.800000011920929px 0px 0px 0px;
    padding: 0px 0px;
    width: 16vw;
    border: 1px solid #6A6665;
    max-width: 550px;
    color: white;

}

.accordion-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    text-align: center;
    border-bottom:1px solid #6A6665;
    color: white;


}
.accordion-header p{
    font-size: 17px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    color: white;
    text-align: left;
    
}
.accordion-icon-container {
    display: flex;
    align-items: center;
}

.accordion-icon {
    margin-right: 5px;
    transition: transform 0.3s;
    align-items: center;
    text-align: center;
}

/* .accordion-icon.open {
    transform: rotate(45deg);
  } */

h4 {
    margin: 0;

}

.mkl {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 6px 8px;
    color: white;

}
.fc66 , .fc77{
    font-size: 17px;
    font-weight: 100;
    display: flex;
    text-align: center;
    align-items: center;
    gap: 4px;
}

.fc66 img{
    height: 16px;
    width: 20px;
}
.fc77 img{
    height: 14px;
    width: 20px;
}

.address {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px;
    border-radius: 5px;
}


@media (max-width: 767px) {

    .accordio{
        width: 70vw;
        padding: 0px;
    }

}


@media (min-width: 768px) and (max-width: 1024px) {

.accordio{
    width: 40vw;
    padding: 0px;
}

}

@media (width < 328px) {
    .accordio{
        overflow: scroll;
    }
}
/* Styling for the container with id "termsandservice"*/
.containera h3 {
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 1300px;
    /* padding: 0 10.416666666666668VW !important; */
    padding: 30px 0px;
    font-size: 50px;
    font-family: 'BlueLB';
    color: #D7BD92;

}

/* Styling for the element with class "term-blk" inside the container */
.term-blk {
    padding-top: 90px;
    text-align: justify;
    line-height: 35px;
}

.containera h4 {
    padding: 20px 0px;
    font-weight: 600;
}

/* Styling for p elements inside the container */
.containera p {
    color: #000000;
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;

}

.containera {
    margin: auto;
    margin-bottom: 50px;
    padding: 90px;
}

@media screen and (max-width: 768px) {

    .containera {
        margin: auto;
        padding: 20px;
        margin-bottom: 50px;
        padding: 40px;
    }

    .der1 {
        font-size: 45px;
        margin-top: 60px;
        color: #D7BD92;

    }

    /* Styling for the container with id "termsandservice"*/
    .containera h3 {
        display: flex;
        justify-content: center;
        font-size: 40px;
        text-align: center;
        /* padding: 0 10.416666666666668VW !important; */
        padding: 30px 0px;
    }

    /* Styling for the element with class "term-blk" inside the container */
    .term-blk {
        line-height: 35px;
        width: 100%;
        margin: auto;
        padding: 0px;
    }
    .term-blk h3{
        line-height: 70px;
        font-size: 40px;
    }



    /* Styling for p elements inside the container */
    .containera p {
        color: #000000;
        font-size: 16px;
        letter-spacing: 1px;

    }

}
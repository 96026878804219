.me1 {
    width: 100vw;
    border: 2px;
    height: auto;
}

.mua {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    height: 700px;
    padding-top: 70px;
    width: 100vw;

}


/* .popo {
    width: 177px;
    height: 50px;
}

.popo p {
    font-family: Inter Inter, sans-serif;
    font-weight: 600;
    line-height: 50px;
    text-align: left;
    font-size: 30px;

} */

.mua1 {
    text-align: left;
    height: 600px;
    padding:0px 70px;
    width: 50vw;
}

.mua2 {
    width: 50vw;
    height: 600px;
    
}

.mua2 iframe{
    height: 550px;
    width: 550px;
}

.mua1 h6 {
    font-size: 25px;
}

.mua1 p {
    font-size: 16px;
    margin-bottom: 10px;
}

.me1 {
    background-color: #ffff;
}

@media (max-width: 1023px) {
    .mua {
        flex-direction: column-reverse;
        justify-content: center;
        padding:0px 0px;
        width: 100vw;
        height: 1200px;
        padding-top: 30px;
    }
    
    .mua2 iframe{
        width: 80vw;
        height: 500px;
        margin:0px auto;

    }
    .mua1{
        width: 100vw;
        height: 500px;

    }
    .mua2 {
        width: 100vw;
        height: 600px;
        
    }

    }
